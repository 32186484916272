.hp-form {
  max-width: 560px;
  min-height: 452px;
  margin: 0 auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
  border: 1px solid white;
  color: black;
  text-align: left;
  box-shadow: 0 10px 10px 0 rgba(#000000, 0.4);
}

.hp-form p {
  font-weight: 200;
  color: black;
}

.hp-form #price{
  color: #b4b4b4;
}

.hp-form .packages .form-check-label{
  display: grid;
  grid-template-columns: 3fr 1fr;
}


.hp-form .content-center{
  text-align: center;
}


.hp-form .content-center img{
  max-width: 30%;
}

.hp-form .divOff {
  transition: opacity 0.5s;
  opacity: 1;
}

.hp-form .divOff.hidden {
  opacity: 0;
  pointer-events:none;
}

.hp-form p.form-subtitle {
  text-align: center;
}

.hp-form .form-group .field-error.form-control {
  border: 1px rgb(198, 63, 63) solid;
}

.hp-form .form-information {
  font-style: italic;
  font-size: 0.6rem;
  font-weight: lighter;
  margin-top: -10px;
}

.hp-form .btn {
  width: 100%;
  margin: 0 auto;
}

.hp-form .btn.next .send {
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: white;
  border: 1px solid green;
  border-radius: 20px;
  color: green;
}

.hp-form .btn.back .send {
  width: 50%;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0;
  background-color: white;
  border: 1px solid gray;
  border-radius: 20px;
  color: gray;
}

.hp-form .btn.next .send:hover {
  background-color: green;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  -webkit-transition: 1s;
}

.hp-form .btn.back .send:hover {
  background-color: gray;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  -webkit-transition: 1s;
}

.bill-item {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.hp-form .cgv-text.field-error,.cgv-text.field-error p, .cgv-text.field-error a{
  color: rgb(198, 63, 63);
}

.hp-form .cgv-text label{
  padding: 0.4rem;
}

.hp-form .option .photo {
  padding: 20px;
  width: 100%;
  text-align: center;
  float: left;
  height: 240px;
}

.hp-form .option .photo img {
  max-height: 100%;
  width: 100%;
  background: white;
}

.hp-form .option .description #price{
         color: #515151;
         font-weight: 300;
         padding-top: 15px;
         margin: 0;
         font-size: 30px;
         font-weight: 300;
}

.hp-form .option .description h3 {
        color: #515151;
        margin: 0;
        text-transform: uppercase;
        font-weight: 500;
       }

.hp-form .option .description  h4 {
         margin: 0;
         color: #727272;
         text-transform: uppercase;
         font-weight: 500;
         font-size: 12px
       }

.hp-form .option .description  p,.hp-form .option .option-choice a {
  text-align: justify;
  font-size: 15px;
  line-height: 20px;
  color: #727272;
  text-decoration: none;
  margin: 0;
}

.hp-form .form-text span{
  color: #a60000;
}

.hp-form .option .option-choice {
  text-align: right;
}

.hp-form .option .btn.next .send {
     outline: 0;
     border: 0;
     background: none;
     border: 1px solid #d9d9d9;
     padding: 10px 20px;
     margin-bottom: 30px;
     color: #515151;
     text-transform: uppercase;
     width: 80%;
     font-family: inherit;
     margin-right: 5px;
     transition: all 0.3s ease;
     font-weight: 500;
 }

.hp-form .option .btn.next .send:hover {
  background: darken(white, 2%);
  border: 1px solid #aedaa6;
  color: #aedaa6;
   cursor: pointer;
}

.hp-form .option .btn.next .send i{
  padding-left: 10px;
  padding-right: 10px;
}

.hp-form .visible-form{
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  perspective: 600px;
  transition: .5s;
  transform: rotateX(0deg);
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transition: .5s;
}

.hp-form .visible-form.hidden{
  transform: rotateX(-180deg);
}


@media (max-width:500px){
  .hp-form {
    border-radius: 0px;
  }
}
