@import url(https://fonts.googleapis.com/css?family=Lusitana);

$bp-small: 500px;
$bp-arrow: 700px;
$font-primary: 'Lusitana', sans-serif;
$color: #007bff;
$color-bg: #007bff;
$color-dark: darken($color, 10%);
$color-lite: lighten($color, 10%);

@mixin position-center {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: auto;
}

.chapitres-liste .chapitre-menu-close {
  opacity: 0;
  content: '×';
  color: white;
  position: relative;
  top: 20%;
  margin: 0;
  left: -4px;
  font-family: Arial, sans-serif;
  font-size: 76px;
  line-height: 0;
  transition: opacity 0.4s ease-out;
  opacity: 0.6;
  background-color: transparent;
  z-index: 2;
  border: none;
  outline: none;
  transition: opacity 0.2s ease-out;
  &:before {
    content: "";
    @include position-center;
    right: auto;
    width: 100%;
    background: linear-gradient(
      to bottom,
      transparent 20%
    );
    transition: opacity 0.2s ease-out,
                width 0.2s 0.2s ease-out;
  }
  &:after {

  }
  &:active {
    transform: translateY(2px);
  }
  &:hover {
    opacity: 1;
  }
  .open & {
    opacity: 1;
    &:before {
      opacity: 0;
      width: 0;
    }
    &:after {
      opacity: 1;
      transform: translate3d(0,0,0)
                 rotate(360deg);
      transition: transform 0.4s 1s ease-out,
                  opacity 0.4s 1s ease-out;
    }
  }
}
.chapitres-liste ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    cursor: pointer;
    padding: 6px 20px;
    font-size: 20px;
  }
  a{
    color: #6cc6ff;
  }
}
.chapitres-liste .chapitre-menu-toggle {
     font-family:sans-serif;
    color:white;
    text-decoration:none;
    text-transform:uppercase;
    letter-spacing:2.8px;
    background-color: $color;
    border-radius:5rem;
    box-shadow: 1px 2.9px 16px rgba(27,139,249,0.4);
    transition:0.6s cubic-bezier(0.01, 1.69, 0.99, 0.94);


  &:hover {
    box-shadow: 3px 4.9px 16px rgba(27,139,249,0.6);
   letter-spacing:5px;
  }


}
.chapitres-liste nav {
  z-index: 50;
  position: fixed;
  top: -150%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  overflow: hidden;
  &:before {
    content: '';
    @include position-center;
    background: rgba($color-dark, 0.98);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04), translateY(9999px);
    overflow: hidden;
  }
  .open & {
    top: 0;
    &:before {
      animation: menu-animation 0.8s ease-out forwards;
    }
  }
}
.chapitres-liste ul.menu {
  width: 90%;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  backface-visibility: hidden;
  perspective: 1000;
  color: white;
  li {
    opacity: 0;
    text-align: center;
    transform: translate3d(0,36px,0);
    &:before {
      content: '';
      @include position-center;
      left: auto;
      background-color: white;
      height: 100%;
      width: 0;
      overflow: hidden;
      transition: width 0.14s ease-out;
    }
    &:after {
      opacity: 0;
      content: attr(data-text);
      @include position-center;
      color: $color;
      overflow: hidden;
      transform: translate(-24px, 6px);
      transition: transform 0.1s ease-out,
                  opacity 0.1s ease-out;
    }
    &:hover {
      &:before {
        left: 0;
        right: auto;
        width: 100%;
      }
      &:after {
        opacity: 1;
        padding: 0 20px;
        transform: translate(0px, 6px);
        transition: transform 0.2s 0.14s ease-out,
                    opacity 0.2s 0.14s ease-out;
      }
    }
    .open & {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: transform 0.2s ease-out,
                  opacity 0.2s ease-out;
      @for $i from 1 to 5 {
        &:nth-child(#{$i}) { transition-delay: $i * 0.1s + 0.65s; }
      }
    }
  }
}

.chapitre-menu-toggle.hidden{
  display: none;
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04)
               translateY(300%);
  }
  40% {
    transform: scale(0.04)
               translateY(0);
    transition: ease-out;
  }
  40% {
    transform: scale(0.04)
               translateY(0);
  }
  60% {
    opacity: 1;
    transform: scale(0.02)
               translateY(0px);
  }
  61% {
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}


@media (max-width:700px){
  .chapitres-liste .chapitre-menu-close {
    position: absolute;
    top: 15%;
    left: 10px;
    right: auto;
    font-size: 40px;
  }

  .chapitres-liste ul li{
    font-size: 15px;
  }
}
