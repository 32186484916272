.home-container{
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: lighter;
  text-align: center;
  min-height: 80vh;
}



*.flashit{
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
}

/********* HEADER *********/

.home-container .header{
  position: relative;
  padding-top: 30px;
  min-height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  text-align: center;
  padding-bottom: 8%;

}

.home-container .header .slide{
  width: 100%;
  height: 100%;
  z-index: -1;
  position : absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: opacity 3s ease-in-out;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  opacity: 0;
}

.home-container .header .slide.show1{
  background-image: url('./img/header-1.jpg');
  background-size: cover;
}


.home-container .header .slide.show1.show,.slide.show2.show,.slide.show3.show,.slide.show4.show {
    opacity: 1;
}


.home-container .header .header-left{
  margin-left: 50px;
  /*background: #FFFFFF90;*/
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.home-container .header .header-left h1{
    font-size: 80px;
    line-height: 1.205;
    font-family: "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
    font-weight: 300;
    color: white;
}

.home-container .header .header-left .Iam {
  font: normal 40px/50px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  line-height: 1.205;
  color: white;
}
.home-container .header .header-left .Iam p {
  height: 50px;
  float: left;
}

.home-container .header .header-left .Iam b {
  float: left;
  overflow: hidden;
  position: relative;
  height: 50px;
  top: 40px;
  padding-left: 100px;
}

.home-container .header .header-left .Iam .innerIam {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;


/*animation*/
-webkit-animation:move 5s;
   -moz-animation:move 5s;
    -ms-animation:move 5s;
     -o-animation:move 5s;
        animation:move 5s;
/*animation-iteration-count*/
-webkit-animation-iteration-count:infinite;
   -moz-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
     -o-animation-iteration-count:infinite;
        animation-iteration-count:infinite;
/*animation-delay*/
-webkit-animation-delay:1s;
   -moz-animation-delay:1s;
    -ms-animation-delay:1s;
     -o-animation-delay:1s;
        animation-delay:1s;
}
@keyframes move{
  0%  { top: 0px; }
  20% { top: -50px; }
  40% { top: -100px; }
  60% { top: -150px; }
  80% { top: -200px; }
}

@-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@-moz-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@-o-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}


.home-container .header .cta-button{
  width: 60%;
  margin: 0 auto;
  margin-top:200px;
}

.home-container .header .cta-button button{
  transition: transform .3s ease-in-out;
  border-radius: 4px;
  color: #fff;
  background: #4284fb;
  box-shadow: 0 8px 24px rgba(55,56,60,.25);
  text-align: center;
  font: 700 21px/1.71 "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 700;
  font-stretch: normal;
  font-size: 21px;
  line-height: 1.71;
  font-family: "Apercu Pro", "Helvetica Neue", Helvetica, sans-serif;
  padding: 11px 22px 10px;
  min-width: 200px;
  border: 2px solid #4284fb;
}

.home-container .header .cta-button button:hover{
  transform: translateY(-5px);
  box-shadow: 0 15px 24px rgba(55,56,60,.25);
}






/** SECTION 1 **/

.home-container .section.section1 h2{
  color:#3d92ca;
  margin-bottom: 20px;
}

.home-container .section.section1,.section.section2{
  margin: 50px;
}

.home-container .section h2 span{
  text-decoration: underline;
}


.home-container .spinner-header .spinner-border{
  position: absolute;
  z-index: -10;
  text-align: center;
  margin-top: 20px;
}

.home-container .spinner-header.hidden{
  display: none;
}

/********* SECTION 2 *********/

.home-container .section.section2{
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #ffffff, #66afda20);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #ffffff, #66afda20); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top : 100px;
  color:#3d92ca;
}

.home-container .section2-container .howto-div{
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4,1fr);
  text-align: center;
  color: black;
}

.home-container .section2-container .howto-div img{
  max-width: 100%;
  height: 120px;
  margin: 0 auto;
  position: absolute;
  top : -50px;
  left: 10px;
}

.home-container .section2-container .howto-div .card-title a{
  height: 100px;
  line-height: 100px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #FFBC42;
}

.home-container .section2-container .howto-div .card-title span{
  vertical-align:middle;
  display:inline-block;
  line-height: 1.4rem;
}

.home-container .section2-container .howto-div .card-text span{
  font-weight: bold;
}

.home-container .section2-container button{
    width: 50%;
    max-width: 300px;
    border: 1px #3d92ca solid;
    border-radius: 20px;
    height: 50px;
    background: white;
    color: #3d92ca;
    font-size: 1.1rem;
}

.home-container .section2-container button:hover{
  border: 1px white solid;
  background: #3d92ca;
  color: white;
}

.home-container .section2-container .home-services-list{
  padding-top: 50px;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 5px;
  height: 300px;
}

.home-container .section2-container .home-services-list .home-service *{
  width: 100%;
}

.home-container .section2-container .home-services-list .home-service ul{
  list-style-type:none;
  padding: 0;
  margin-top: 20px;

}

.home-container .section2-container .home-services-list .home-service button{
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 20px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    text-align: center;
}

.home-container .section2-container .home-services-list .home-service  button:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
/********* SECTION 3 *********/

.home-container .section3-container .section3-title{
  padding: 40px;
}

.home-container .section3-container .slideContent{
  display: grid;
  grid-template-columns: 10fr;
  height: 100%;
}

.home-container .section3-container .slideContent .slide-img img{
  width: 100%;
}

.home-container .section3-container .slideContent .slide-text{
  width: 70%;
  margin: 0 auto;
  right: 0;
  text-align: right;
  padding: 50px;
}

.home-container .section3-container .slideContent .slide-text .slide-text-part2{
  font-style: italic;
}
.home-container .section3-container .slideContent .slide-text .slide-text-part2 i{
  margin-right: 10px;
}
/********* SECTION 4 *********/

.home-container .section4-container{
  text-align: center;
  margin: 50px;
}

.home-container .section4-container .section4-buttons{
    width: 50%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 20px;
}

.home-container .section4-container .section4-buttons button{
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  text-align: center;
}

.home-container .section4-container .section4-buttons button:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

/********* SECTION 5 *********/

.home-container .sectionContact-container{
  width: 50%;
  max-width: 800px;
  min-width: 320px;
  margin: 0 auto;
  margin-top: 100px;
}

.home-container .sectionContact-container h4{
  margin-bottom: 70px;
}

.home-container .sectionContact-container textarea{
  height: 120px;
  resize: none;
}

.home-container .sectionContact-container .contact-form .form-group .field-error.form-control {
  border: 1px red solid;
}

.home-container .sectionContact-container .contact-form .form-group p{
  text-align: right;
  font-size: 0.8rem;
}

.home-container .custom-tag {
    max-width: 100%;
    height: 500px;
    background: #2193b0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }

.home-container .text-danger{
    color:white!important;
  }

.home-container   .carousel-inner > .item {
      position: relative;
      display: none;
      -webkit-transition: 0.6s ease-in-out left;
      -moz-transition: 0.6s ease-in-out left;
      -o-transition: 0.6s ease-in-out left;
      transition: 0.6s ease-in-out left;
  }

.socialLogos i{
  font-size: 4rem;
  color: #008cde;
  margin: 20px;
}

.home-container .sectionContact a{
    width: 90%;
    padding: 18px 20%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 20px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    text-align: center;
}

.home-container .sectionContact  a:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}



/*** SERVICES **/
.home-container.services .header-content{
  padding : 40px;
  height: 20vh;
  background: rgba(255, 255, 255, 0.35);
}

.home-container.services .body-content{
  margin-top : 20px;
  margin-bottom : 20px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.35);
}

.home-container.services .body-content .block-step{
  text-align: center;
  height: 220px;
  border: rgb(7, 145, 196, 0.5) solid 0.1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  display : table;
  width: 100%;
  transition: box-shadow .5s;
}

.home-container.services .body-content .block-step div{
  display: table-cell;
  vertical-align: middle;
}


.home-container.services .body-content .block-step h2, .home-container.services .body-content a{
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(0, 135, 233);
  text-decoration: none;
  height: 80px;
}

 .home-container.services .body-content .block-step:hover{
   transform: scale(1.01) ;
   box-shadow: 0 0 11px rgba(33,33,33,.2);
 }

.home-container.services .body-content .block-step img{
  height: 160px;
}


@media (max-width:1158px) {
  .home-container .section2-container .howto-div{
    width: 100%;
  }

  .home-container .section4-container .section4-buttons{
    width: 100%;
  }

  .home-container .section3-container .slideContent{
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }

  .home-container .section3-container .slideContent .slide-text{
    font-style: italic;
    padding: 20px;
    padding-top: 70px;
  }
}

@media (max-width:800px) {
  .home-container .header .header-left h1{
      font-size: 70px;
      line-height: 1;
      color: white;
  }

  .home-container .header .header-left .Iam {
    font: normal 30px/40px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  }

  .home-container .header .header-left .Iam p {
    height: 40px;
  }

  .home-container .header .header-left .Iam b {
    height: 40px;
    padding-left: 0px;
  }

  @keyframes move{
  0%  { top: 0px; }
  20% { top: -40px; }
  40% { top: -80px; }
  60% { top: -120px; }
  80% { top: -160px; }
  }

  @-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }
  @-moz-keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }
  @-o-keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }
  @keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }

  .home-container.services .body-content{
    display: inline-block;
    height: 100%;
  }

  .home-container.services .body-content .block-step h2{
      font-size: 1.4rem;
      height: 50px;
  }
  .home-container.services .body-content .block-step{
    margin:  10px;
    height: 100%;
  }

}

@media (max-width:768px) {



  .home-container .section2-container .home-services-list {
    width: 100%;
    display: inline-block;
  }

  .home-container .section.section2{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home-container .section2-container .howto-div .card-title{
      height: auto;
  }
  .home-container .section2-container .howto-div{
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-row-gap: 60px;
  }

  .home-container .section2-container .howto-div button{
    display: none;
  }


  .home-container .section3-container .slideContent .slide-text{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    text-align: right;
    padding: 50px;
  }
}
@media (max-width:660px) {
  .home-container .section3-container .slideContent{
    display: inline-block;
  }

  .home-container .section3-container .slideContent .slide-text{
    text-align: center;
  }

  .home-container .section3-container .slideContent .slide-img{
    display: none;
  }

  .text-danger.carousel-caption.d-none.d-md-block{
    display: block!important;
  }


}
@media (max-width:600px) {
  .home-container .section4-container .section4-buttons{
    width: 90%;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-row-gap: 20px;
  }

  .home-container svg{
    display: none;
  }
}
@media (max-width: 495px) {
  .home-container .header{
    min-height: 50vh;
  }
  .home-container .header .header-left h1{
      font-size: 30px;
      line-height: 1;
      color: white;
  }

  .home-container .header .header-left .Iam {
    font: normal 20px/30px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  }

  .home-container .header .header-left .Iam p {
    height: 30px;
  }

  .home-container .header .header-left .Iam b {
    height: 30px;
  }

  @keyframes move{
  0%  { top: 0px; }
  20% { top: -30px; }
  40% { top: -60px; }
  60% { top: -90px; }
  80% { top: -120px; }
  }

  @-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }
  @-moz-keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }
  @-o-keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }
  @keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }

  .home-container .header .cta-button button{
    min-width: 100px;
    font-weight: 500;
    font-stretch: normal;
    font-size: 15px;
  }
}
