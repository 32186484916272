.legal-page-content{
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.legal-content{
  width: 80%;
  margin: 0 auto;
  padding: 50px;
  background: rgba(255, 255, 255, 0.7);
  text-align: justify;
}

.legal-page-content h1{
  text-align: center;
  color:  #2193b0;
}

.legal-page-content h2{
  font-size: 1.4em;
  margin: 50px;
}

@media (max-width:850px) {
  .legal-content{
    width: 100%;
    padding: 20px;
  }

  .legal-page-content h2{
    margin: 20px;
  }
}
