.page {
  /*background: url("./img/bg-2.jpg");*/
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  text-align: center;
  padding-bottom: 150px;
}

.page .title {
  background: rgb(255, 255, 255, 0.7);
  color : #3d92ca;
  padding: 20px;
  font-size: 1.4rem;
}

.page .img-sec {
  min-width: 150px;
  max-width: 20%;
  margin-top:5%;
}

.page .form-title{
  background: rgb(255, 255, 255, 0.5);
  margin-top : 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: 300;
}

.page .form-title span{
  color: rgb(235, 72, 72);
  font-weight: 400;
}
