.constituer-main-container{
  min-height: 80vh;
  width: 100%;
  text-align: center;
}

.constituer-content p{
  text-align: justify;
}

.constituer-main-container .top-image{
  padding-top: 70px;
  background-position: top;
  background-image: url('./img/constituer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.constituer-main-container .top-image.procedure{
  background-image: url('./img/fleche.jpg');
}

.constituer-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.constituer-main-container .top-image-title h1{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.constituer-main-container .top-image-title h1 span{
  color: #cf3030;
}


.constituer-main-container .constituer-content-header{
  width: 80%;
  margin : 0 auto;
  padding-top : 50px;
}
.constituer-main-container .constituer-content-header p{
  text-align: center;
}

.constituer-main-container .constituer-content-header p span{
  font-weight: 600;
}

.constituer-main-container .attention{
  margin : 20px;
  padding: 10px;
  border: solid rgba(246, 57, 46, 0.84) 1px;
  background: rgba(185, 67, 53, 0.1);
  border-radius: 10px;
}

.constituer-main-container .constituer-top-image img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.constituer-main-container a{
  color:  rgb(0, 130, 179);
  text-decoration: none;
}

.constituer-main-container a:hover{
  text-decoration: none;
}

.constituer-main-container ul{
  text-align: center;
  list-style: none;
  padding-inline-start: 0;

}

.constituer-main-container li,.constituer-main-container .list-spaced{
  background: #7ed0e9;
  padding: 10px;
  margin: 0 0 10px 0;
  transition: background 0.5s ease-out;
}

.constituer-main-container ul .list-spaced{
  margin: 30px 0 20px 0;
  width: 110%;
}

.constituer-main-container .list-secondary{
  background: white;
  border: solid 1px #7ed0e9;
  color : black;
  width: 80%;
  margin : 0 auto;
  margin-bottom : 20px;
}

.constituer-main-container li:hover{
  color: white;
  background: rgb(30, 140, 164);
}

.constituer-main-container .list-secondary:hover{
  background: white;
  color : #7ed0e9;
}

/*** PROCEDURE **/
.constituer-section.procedure.section-1-container{
  text-align: center;
}

.constituer-section.procedure.section-1-container .form-selection{
    width: 70%;
    margin: 0 auto;
}

.constituer-section.procedure.section-1-container .form-selection ul{
  text-align: left;
}

.constituer-section.procedure.section-1-container input[type="radio"]{
  margin: 0 10px 0 10px;
}

.constituer-main-container .procedure-form-result{
  background: #7ed0e9;
  padding: 10px;
  margin: 10px 0 10px 0;
}

.constituer-main-container .procedure-form-result p{
  text-align: center;
  font-size: 1.2rem;
}
.constituer-main-container .procedure-form-result p span{
  text-align: center;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
}


.constituer-section {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.constituer-section .option_select{
  margin-top:10px;
  margin-bottom: 10px;
}

.constituer-section .deposer-result .deposer-result-content{
  border: solid 1px rgb(83, 202, 252);
  background: rgba(100, 170, 180, 0.2);
  margin-top: 50px;
  margin-bottom : 100px;
  padding: 10px;
}

.constituer-section .deposer-result .deposer-result-content .links{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-top : 20px;
}
.constituer-section .deposer-result .deposer-result-content .links p {
  text-align: center;
}

.constituer-main-container button{
  margin: 50px;
}

.constituer-content-header.instruction{
  width: 80%;
  padding: 20px;
  margin: 0 auto;
}

/** DECISION **/
.constituer-content-header.decision{
  width: 60%;
  margin-bottom : 100px;
}
.constituer-section.decision.section-1.left{
  text-align: left;
}

.constituer-section.decision.section-1-container h3{
  text-align: center;
  margin-top: 50px;
}
.constituer-section.decision.section-1-container button{
  width: 100%;
  margin: 0 auto;
}

@media (max-width:750px) {
  .constituer-main-container .constituer-content-header{
    width: 90%;
    margin: 0 auto;
    padding: 0;
    padding-top : 30px;
  }

  .constituer-main-container ul{
    padding: 0;
  }

  .constituer-section.procedure.section-1-container .form-selection{
    width: 80%;
  }

  .constituer-section {
    width: 95%;
    margin: 0 auto;
  }

  .constituer-section .deposer-result .deposer-result-content .links{
    display: inline-block;
  }


}
