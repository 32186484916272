.main-404-container{
    height: 100vh;
    text-align: center;
    padding: 40px;
}

.main-404-container img {
  max-width: 100%;
  height: 200px;
}
