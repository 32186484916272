.offline-content{
  height: 100vh;
}

.charte-main-container{
  width: 100%;
  text-align: center;
}



.charte-main-container .top-image.charte{
  background: url('./img/top-img.jpg');
  padding-top: 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.charte-main-container .top-image img{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.charte-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
  font-weight: 200;

}

.charte-main-container .top-image-title h2 span{
  font-weight: normal;
}

.qcm-reminder{
  max-width: 30%;
  min-width: 300px;

  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}


.charte-content-header{
    width: 80%;
    margin: 0 auto;
    padding-top : 50px;
}

.charte-content{
  text-align: justify;
  width: 80%;
  margin: 0 auto;
}

.charte-content .image-sep{
  height: 250px;
  background-image: url("./img/sep-img.jpeg");
  background-size: 100%;
  background-position: bottom;
  -webkit-transition:background-size .5s ease-in-out;
	transition:background-size .5s ease-in-out;
}

.charte-content .image-sep:hover {
	background-size: 120%;
}


@media (max-width:770px) {
    .charte-main-container .charte-content-description{
      display: inline-block;
    }

    .charte-main-container .charte-description h1{
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
    .charte-main-container .charte-content-description .charte-content-chapitres{
      font-size: 1rem;
    }

    .charte-main-container .charte-content-description .red-line{
      border-top: 2px solid red;

    }
}
