.preparer-main-container{
  width: 100%;
  text-align: center;
}

.preparer-main-container nav{
  width: 100%;
  position: fixed;
  z-index: 10;
}

.preparer-main-container .top-image{
  padding-top: 70px;
  background-image: url('../img/deroulement.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.preparer-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.preparer-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.preparer-main-container .top-image-title h2 span{
  color: #cf3030;
}


.preparer-main-container .questions-persos-content-header{
  padding: 40px;
  text-align: center;
}

.questions-perso-section{
  margin-bottom: 80px;
}
