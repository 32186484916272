.login-page-content{
  width: 100%;
  min-height: 80vh;
  background: #6dd5ed;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.register-page-content{
  width: 100%;
  min-height: 80vh;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding-bottom: 50px;
}

.page-content{
  width: 60%;
  margin: 0 auto;
  padding-top : 50px;
  color: white;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
}

.page-content .content-login{
  padding-bottom: 100px;
}

.page-content .lg-form{
  text-align: center;
  width: 30%;
  min-width: 350px;
  margin: 0 auto;
  margin-top : 50px;
  padding: 20px;
  padding-top: 50px;
  border-radius: 30px;
  background-color: white;
  color: black;
}
.page-content .lg-form a{
  text-decoration: none;
}

.page-content .mdp-forget{
    background: transparent;
    border: transparent;
    color : white;
    font-weight: lighter;
}

.page-content .lg-form .roundblue{
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 40px;
  background: white;
  border: 0.05rem solid #008cde;
  color: #008cde;
}

.page-content .lg-form .roundblue:hover{
  border: 0.05rem solid #008cde;
  background: #008cde;
  color: white;
}

.page-content .lg-form .fb-login button{
  height: 60px;
  font-size: 0.7rem;
  box-shadow: none;
  width: 100%;
}

.page-content .register-link{
  text-decoration: none;
  color: white;
}
.lg-form .form-group .field-error.form-control {
  border: 1px red solid;
}
.lg-form  input#cgucheck{
  margin: .4rem;
}

.lg-form .cgu-text{
  width: 90%;
  margin: 0 auto;
  color: black;
  text-align: justify;
  font-size: 0.8rem;
}
.lg-form .cgu-text.field-error,.lg-form .cgu-text.field-error a{
  color: #dc3545;
}

.lg-form .custom-switch{
  position: relative;
}
.lg-form .custom-control-label{
  color: black;
}
@media (max-width:767px) {
  .page-content{
    width: 100%;
  }
  .page-content .lg-form{
    width: 50%;
  }

  .page-content h2{
    padding-left: 5px;
    padding-right: 5px;

  }
}
