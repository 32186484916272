.statistics {
  display: grid;
  grid-template-rows:repeat(1, minmax(100vh, auto));
}

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');


.heading {
  background: white;
  height: 5%;
  margin: 10px;
  padding: 10px;
}

.swiper-container {
  width: 100%;
  height: 90%;
}
.swiper-slide {
  text-align: center;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

// Image Accordions General
.container-general {
  padding: 75px 0;
  margin: 0 auto;
  width: 100%;
  .gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
    .item {
      flex: 1;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: none;
      transition: all 0.8s ease;
      &:hover,&:active {
        flex: 7;
        & .item-title{
          display: block;
        }
      }
    }
  }
}

.container-general .item-title{
  background: rgba(255, 255, 255, 0.5);
  font-size: 6rem;
  padding: 20px;
  padding-top: 20%;
  display: none;
  & h3{
    text-decoration: none;
    font-weight: normal;
  }
  & span{
    font-size: 8rem;
    font-weight: bolder;
  }
  & p {
    font-size: 1rem;
    text-align: center;
  }
}
// Image Accordions Effect 1

.wrap-effect-1 {
  .item {
    &:first-of-type {
      background-image: url('./img/population.jpg');
    }
    &:nth-of-type(2) {
      background-image: url('./img/territoire.jpg');
    }
    &:nth-of-type(3) {
      background-image: url('./img/fleuve.jpg');
    }
    &:nth-of-type(4) {
      background-image: url('./img/agglo.jpg');
    }
    &:last-of-type {
      background-image: url('./img/metropole.png');
    }
  }
  .item-title{
    color: white;
  }
}

// Image Accordions Effect 2
.wrap-effect-2 {
  .item {
    &:hover,&:active  {
      transform: translate3d(0, 0, 100px);
      box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      filter: saturate(2) contrast(120%);
    }
    &:first-of-type {
      background-image: url('./img/speaker.jpg');
    }
    &:nth-of-type(2) {
      background-image: url('./img/Ktx3.gif');
    }
    &:nth-of-type(3) {
      background-image: url('./img/yellow.jpeg');
    }
    &:nth-of-type(4) {
      background-image: url('./img/yellow.jpeg');
    }
    &:last-of-type {
      background-image: url('./img/yellow.jpeg');
    }
  }
}

// Image Accordions Effect 3
.wrap-effect-3 {
  .item {
    &:hover,&:active  {
      transform: translate3d(0, 0, 100px);
      box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    }
    &:first-of-type {
      background-image: url('./img/old.jpeg');
    }
    &:nth-of-type(2) {
      background-image: url('./img/europe.jpeg');
    }
    &:nth-of-type(3) {
      background-image: url('./img/vote.jpeg');
    }
    &:nth-of-type(4) {
      background-image: url('https://cdn.dribbble.com/users/729829/screenshots/4742049/galshir-samurai.png');
    }
    &:last-of-type {
      background-image: url('./img/euro.jpeg');
    }
  }
}

// Image Accordions Effect 4
.wrap-effect-4 {
  .item {
    &:hover,&:active  {
      box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      filter: saturate(2) contrast(120%);
    }
    &:first-of-type {
      background-image: url('./img/five.jpeg');
    }
    &:nth-of-type(2) {
      background-image: url('./img/work.jpeg');
    }
    &:last-of-type {
      background-image: url('./img/ladefense.jpeg');
    }
  }
}

.inspire {
  border: 1px solid rgba(22, 76, 167, 0.6);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-decoration:none;
  margin: 10px;
  padding: 10px;
  &:after {
    background: #38ef7d;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .4;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
  &:hover,&:active  {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    .container-general .item-text{
      display: block;
    }

  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(10deg) translate3d(0, 0, 0);
  }
  50% {
    transform: rotate(-10deg) translate3d(0, 0, 0);
  }
  75% {
    transform: rotate(5deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0)
  }
}
