.not-connected-quizz-content{
  height: 100vh;
  text-align: center;
  padding-top: 20px;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
}

.landing-quizz-content{
  min-height: 100vh;
  text-align: center;
  padding-top: 20px;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: url("./img/study.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}
.landing-quizz-content .quizz-presentation{
  width: 80%;
  margin: 0 auto;
  color: black;
}

.landing-quizz-content .quizz-presentation.text{
  color: black;
  padding: 20px;
  background: rgba(255, 255, 255, 0.85)
}
.landing-quizz-content .react-quiz-container{
  margin:0 auto;
  background: white;
  padding: 10px;
  color: black;
  border-radius: 10px;
}

.landing-quizz-content .react-quiz-container h3{
  color:#2193b0;
}

.landing-quizz-content  .nextQuestionBtn.btn{
  margin: 0 auto;
}

.landing-quizz-content .quizz-result{
  background: rgba(255, 255, 255, 0.35);
  padding: 50px;
  color: black;
}

@media (max-width:650px) {
  .landing-quizz-content .quizz-presentation{
    width: 100%;
  }

  .landing-quizz-content .quizz-presentation.title{
    padding: 20px;
  }

  .react-quiz-container{
    max-width: 90%;
  }

  .react-quiz-container .questionWrapper .btn {
    font-size: 12px;
  }

  .react-quiz-container .answerBtn {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .questionWrapperBody h3{
    font-size: 1.1rem;
  }

}
