.timeline-wrapper{
  background: #7F7FD5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom ,#91EAE4, #91EAE4, #86A8E7, #007bff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #91EAE4, #91EAE4, #86A8E7, #007bff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.historyTL-component{
  margin-top:20px;
}

.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns{
  padding-top:50px;
  margin-top: 0;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{
  text-align: left;
  left: 134% !important;
}

.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date{
  right: 134% !important;
  left: auto !important;
}

.vertical-timeline-element-content img{
  max-width: 100%;
  height: auto;
  box-shadow: 1px 5px 10px 2px rgb(161, 161, 161);
  margin-top:20px;
  margin-bottom:20px;

}
