footer {
  width: 100%;
  padding: 25px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222222;
}

footer p {
  font-size: 0.6rem;
  margin: 0;
  font-weight: bold;
}

footer i{
  padding: 10px;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

footer a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

footer a:hover, footer a:focus, footer a:active, footer a.active {
  text-decoration: none;
}

footer .footer-container{
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding:0;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 20px;
}

footer .footer-container div:last-child{
  text-align: center;
  padding-right: 10px;
}


@media (max-width:500px){
  footer .footer-container{
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    text-align: center;

  }

  footer .footer-container div:last-child{
    text-align: center;
  }
}
