.checklist-conditions{
  padding: 5px;
  border: solid 1px #007bff;
  border-radius: 5px;
}

.checklist-conditions .subtitle{
  padding: 20px;
}

.checklist-conditions .content-list li{
  border: solid 1px #007bff;
  background: rgba(#007bff, 0.54);
}

.checklist-conditions .content-list .cond-list{
  padding-left: 20px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: 1fr 20fr;
  text-align: center;
  transition: background 0.5s ease-in;
  grid-column-gap: 5px;
}

.checklist-conditions .content-list .cond-list input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checklist-conditions .content-list .cond-list .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

@media (max-width: 750px){
  .conditions-result .content-list .cond-list{
    padding-left: 0px;
  }
}
