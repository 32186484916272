.livret-main-container{
  width: 100%;
  text-align: center;
}

.livret-main-container nav{
  width: 100%;
  position: fixed;
  z-index: 50;
}

.livret-main-container .top-image{
  padding-top: 70px;
  background-image: url('../img/livret.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.livret-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.livret-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.livret-main-container .top-image-title h2 span{
  color: #cf3030;
}

.livret-content-header{
    width: 80%;
    margin: 0 auto;
    padding-top : 50px;
}

.livret-content-header p{
  width: 90%;
  margin: 0 auto;
  text-align: justify;
}

.livret-content img{
  width: auto;
  height: 100%;
  max-height: 200px;
  margin: 0 auto;
}

.livret-content .chapitres-liste .chapitre-menu-toggle{
  position: relative;
  width: 320px;
  height: 25px;
  margin-top: 20px;
  font-size: 0.7rem;
}

.livret-section{
  margin: 50px;
}

.livret-section h3{
  text-align: center;
  text-decoration: underline;
  font-variant: small-caps;
  margin-bottom: 20px;
}

.livret-section.section-1 p{
  margin-top: 100px;
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.livret-section.section-2 p{
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.livret-section.section-4 img{
  margin-top: 40px;
  width: 100%;
  min-height: 500px;
  object-fit: scale-down;
}

.livret-section.section-end{
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  font-size: 0.8em;
}


.livret-section.section-1 .effect.quoting-focus{
  text-align: center;
}

.effect.quoting-focus{
    color: black;
    flex: 1;
    text-decoration: none;
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
    background-position: 0 88%;
    background-size: 100% 0.2em;
    background-repeat: no-repeat;
    transition: background-size 0.25s ease-in;
  }

.effect.quoting-focus:hover{
  background-size: 100% 88%;
}

/****** DEMOCRATIE *******/
.livret-section.section-2 .blocks-section-pouvoirs{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.blocks-section-pouvoirs .block-section-pouvoir{
  padding: 20px;
  width: 100%;
  min-height: 500px;
  background: #e0e0e0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #e0e0e0, #ffffff, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #e0e0e0, #ffffff, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.blocks-section-pouvoirs .block-section-pouvoir img{
  max-width: 80px;
  max-height: 80px;
  margin: 10px;
}

.blocks-section-pouvoirs .block-section-pouvoir-title{
  margin: 20px;
}

.livret-section.section3-droits-devoirs{
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
}

/****** TERRITOIRE *******/

.livret-section-maps{
  color: black;
  display: grid;
  grid-row-gap: 10px;
  margin: 0 auto;
  background: url('./img/carteFrance.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}

.livret-section-maps.outremer{
  margin-top: 50px;
  color: black;
  display: grid;
  grid-template-rows: repeat(2,1fr);
  grid-row-gap: 10px;
  margin: 0 auto;
  background: url('./img/domtom.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.livret-section-map{
  background: rgba(255, 255, 255, 0.5);
  width: 60%;
  border-bottom: black dotted 0.5px;
  padding: 20px;
  text-align: left;
}

.livret-section-maps.outremer .livret-section-map{
  margin-right: 0;
  margin-left: auto;
}








.livret-content .chapitres{
  width: 80%;
  margin: 0 auto;
}
.livret-content .chapitres h3{
  font-size: 1.65rem;
  margin: 15px 0;
  text-align: center;
}
.livret-content .chapitres ol{
  counter-reset: section;
}
.livret-content .chapitres li {
  list-style-type: none;
  font-size: 1.5rem;
  padding: 15px;
  margin-bottom: 15px;
  background: rgb(0, 141, 210);
  color: rgb(245, 233, 95);
  transition: background-color 0.9s ease;
}
.livret-content .chapitres li:hover {
  background: rgb(0, 178, 255);
}
.livret-content .chapitres i{
  color: white;
  padding-left: 0.5rem;
}
.livret-content .chapitres h4 {
  position: relative;
  padding-bottom: 10px;
  color: white;
}
.livret-content .chapitres h4:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 2px;
  background: white;
}
.livret-content .chapitres p {
  font-size: .9rem;
  line-height: 1.4rem;
  margin-top: 15px;
}
.livret-content .chapitres li::before {
    counter-increment: section;
    content: counter(section);
    text-align: center;
    display: inline-block;
    color: #000;
    border-radius: 50%;
    position: absolute;
    left: -65px;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px;
    font-size: 2rem;
    width: 25px;
    height: 25px;
    border: 2px solid #000;
}

@media (max-width:900px) {
  .livret-section.section-2 .blocks-section-pouvoirs{
    display: inline-block;
  }

  .livret-section.section-2 .block-section-pouvoir{
    min-height: 100px;
    margin: 0;
    margin-bottom: 40px;
  }

  .livret-section-map{
    width: 90%;
  }
}

@media (max-width:700px) {
  .livret-section  {
    margin: 15px;
  }
  .livret-section-map.communes{
    display: inline-block;
  }
  .livret-section-map.communes img{
    margin-top: 20px;
  }

  .livret-section.section-1 .livret-section-maps{
    width: 100%;
  }

  .livret-section.section3-droits-devoirs{
    display: inline-block;
  }

  .livret-main-container .breadcrumb{
    display: none;
  }
}
