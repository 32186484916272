.offline-content{
  height: 100vh;
}

.preparer-entretien-main-container{
  width: 100%;
  text-align: center;
}

.preparer-entretien-main-container .preparer-content-header{
  padding: 40px;
}
.preparer-entretien-main-container .preparer-content-description{
  padding: 50px;
  display: grid;
  grid-template-columns: 8fr 1fr 10fr;
  grid-column-gap: 10px;
}

.preparer-entretien-main-container .top-image img{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.preparer-entretien-main-container .preparer-content-description .preparer-content-page-title{
  color: #3d92ca;
  margin-top : 20px;
}

.preparer-entretien-main-container .preparer-content-description .preparer-content-chapitres{
  margin-top: 20px;
  text-align: left;
  font-size: 1.2rem;
}
.preparer-entretien-main-container .preparer-content-description .red-line{
  border-left: 6px solid red;
}


.qcm-reminder{
  max-width: 30%;
  min-width: 300px;

  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width:770px) {
    .preparer-entretien-main-container .preparer-content-description{
      display: inline-block;
    }

    .preparer-entretien-main-container .preparer-content-description h1{
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
    .preparer-entretien-main-container .preparer-content-description .preparer-content-chapitres{
      font-size: 1rem;
    }

    .preparer-entretien-main-container .preparer-content-description .red-line{
      border-top: 2px solid red;

    }
}
