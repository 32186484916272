@import url(https://fonts.googleapis.com/css?family=Lusitana);
@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
body {
  margin: 0;
  padding: 0;
  font-family: Times, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,body {
  height: 100%;
  margin:0;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-weight: lighter !important;
}

@media (max-width:500px) {
  h2{
    font-size: 1rem;
  }
}

.home-container{
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: lighter;
  text-align: center;
  min-height: 80vh;
}



*.flashit{
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
}

/********* HEADER *********/

.home-container .header{
  position: relative;
  padding-top: 30px;
  min-height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  text-align: center;
  padding-bottom: 8%;

}

.home-container .header .slide{
  width: 100%;
  height: 100%;
  z-index: -1;
  position : absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  opacity: 0;
}

.home-container .header .slide.show1{
  background-image: url(/static/media/header-1.682de708.jpg);
  background-size: cover;
}


.home-container .header .slide.show1.show,.slide.show2.show,.slide.show3.show,.slide.show4.show {
    opacity: 1;
}


.home-container .header .header-left{
  margin-left: 50px;
  /*background: #FFFFFF90;*/
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.home-container .header .header-left h1{
    font-size: 80px;
    line-height: 1.205;
    font-family: "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
    font-weight: 300;
    color: white;
}

.home-container .header .header-left .Iam {
  font: normal 40px/50px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  line-height: 1.205;
  color: white;
}
.home-container .header .header-left .Iam p {
  height: 50px;
  float: left;
}

.home-container .header .header-left .Iam b {
  float: left;
  overflow: hidden;
  position: relative;
  height: 50px;
  top: 40px;
  padding-left: 100px;
}

.home-container .header .header-left .Iam .innerIam {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;


/*animation*/
-webkit-animation:move 5s;
        animation:move 5s;
/*animation-iteration-count*/
-webkit-animation-iteration-count:infinite;
        animation-iteration-count:infinite;
/*animation-delay*/
-webkit-animation-delay:1s;
        animation-delay:1s;
}
@keyframes move{
  0%  { top: 0px; }
  20% { top: -50px; }
  40% { top: -100px; }
  60% { top: -150px; }
  80% { top: -200px; }
}

@-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}


.home-container .header .cta-button{
  width: 60%;
  margin: 0 auto;
  margin-top:200px;
}

.home-container .header .cta-button button{
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  border-radius: 4px;
  color: #fff;
  background: #4284fb;
  box-shadow: 0 8px 24px rgba(55,56,60,.25);
  text-align: center;
  font: 700 21px/1.71 "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 700;
  font-stretch: normal;
  font-size: 21px;
  line-height: 1.71;
  font-family: "Apercu Pro", "Helvetica Neue", Helvetica, sans-serif;
  padding: 11px 22px 10px;
  min-width: 200px;
  border: 2px solid #4284fb;
}

.home-container .header .cta-button button:hover{
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 15px 24px rgba(55,56,60,.25);
}






/** SECTION 1 **/

.home-container .section.section1 h2{
  color:#3d92ca;
  margin-bottom: 20px;
}

.home-container .section.section1,.section.section2{
  margin: 50px;
}

.home-container .section h2 span{
  text-decoration: underline;
}


.home-container .spinner-header .spinner-border{
  position: absolute;
  z-index: -10;
  text-align: center;
  margin-top: 20px;
}

.home-container .spinner-header.hidden{
  display: none;
}

/********* SECTION 2 *********/

.home-container .section.section2{
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #ffffff, #66afda20);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#66afda20));
  background: -webkit-linear-gradient(bottom, #ffffff, #66afda20);
  background: linear-gradient(to top, #ffffff, #66afda20); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top : 100px;
  color:#3d92ca;
}

.home-container .section2-container .howto-div{
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4,1fr);
  text-align: center;
  color: black;
}

.home-container .section2-container .howto-div img{
  max-width: 100%;
  height: 120px;
  margin: 0 auto;
  position: absolute;
  top : -50px;
  left: 10px;
}

.home-container .section2-container .howto-div .card-title a{
  height: 100px;
  line-height: 100px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #FFBC42;
}

.home-container .section2-container .howto-div .card-title span{
  vertical-align:middle;
  display:inline-block;
  line-height: 1.4rem;
}

.home-container .section2-container .howto-div .card-text span{
  font-weight: bold;
}

.home-container .section2-container button{
    width: 50%;
    max-width: 300px;
    border: 1px #3d92ca solid;
    border-radius: 20px;
    height: 50px;
    background: white;
    color: #3d92ca;
    font-size: 1.1rem;
}

.home-container .section2-container button:hover{
  border: 1px white solid;
  background: #3d92ca;
  color: white;
}

.home-container .section2-container .home-services-list{
  padding-top: 50px;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 5px;
  height: 300px;
}

.home-container .section2-container .home-services-list .home-service *{
  width: 100%;
}

.home-container .section2-container .home-services-list .home-service ul{
  list-style-type:none;
  padding: 0;
  margin-top: 20px;

}

.home-container .section2-container .home-services-list .home-service button{
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 20px;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-image: -webkit-gradient(linear, left top, right top, from(#25aae1), color-stop(#4481eb), color-stop(#04befe), to(#3f86ed));
    background-image: -webkit-linear-gradient(left, #25aae1, #4481eb, #04befe, #3f86ed);
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    text-align: center;
}

.home-container .section2-container .home-services-list .home-service  button:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
/********* SECTION 3 *********/

.home-container .section3-container .section3-title{
  padding: 40px;
}

.home-container .section3-container .slideContent{
  display: grid;
  grid-template-columns: 10fr;
  height: 100%;
}

.home-container .section3-container .slideContent .slide-img img{
  width: 100%;
}

.home-container .section3-container .slideContent .slide-text{
  width: 70%;
  margin: 0 auto;
  right: 0;
  text-align: right;
  padding: 50px;
}

.home-container .section3-container .slideContent .slide-text .slide-text-part2{
  font-style: italic;
}
.home-container .section3-container .slideContent .slide-text .slide-text-part2 i{
  margin-right: 10px;
}
/********* SECTION 4 *********/

.home-container .section4-container{
  text-align: center;
  margin: 50px;
}

.home-container .section4-container .section4-buttons{
    width: 50%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 20px;
}

.home-container .section4-container .section4-buttons button{
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  border-radius: 10px;
  moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  background-image: -webkit-gradient(linear, left top, right top, from(#25aae1), color-stop(#4481eb), color-stop(#04befe), to(#3f86ed));
  background-image: -webkit-linear-gradient(left, #25aae1, #4481eb, #04befe, #3f86ed);
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  text-align: center;
}

.home-container .section4-container .section4-buttons button:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

/********* SECTION 5 *********/

.home-container .sectionContact-container{
  width: 50%;
  max-width: 800px;
  min-width: 320px;
  margin: 0 auto;
  margin-top: 100px;
}

.home-container .sectionContact-container h4{
  margin-bottom: 70px;
}

.home-container .sectionContact-container textarea{
  height: 120px;
  resize: none;
}

.home-container .sectionContact-container .contact-form .form-group .field-error.form-control {
  border: 1px red solid;
}

.home-container .sectionContact-container .contact-form .form-group p{
  text-align: right;
  font-size: 0.8rem;
}

.home-container .custom-tag {
    max-width: 100%;
    height: 500px;
    background: #2193b0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
    background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
    background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }

.home-container .text-danger{
    color:white!important;
  }

.home-container   .carousel-inner > .item {
      position: relative;
      display: none;
      -webkit-transition: 0.6s ease-in-out left;
      transition: 0.6s ease-in-out left;
  }

.socialLogos i{
  font-size: 4rem;
  color: #008cde;
  margin: 20px;
}

.home-container .sectionContact a{
    width: 90%;
    padding: 18px 20%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 20px;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-image: -webkit-gradient(linear, left top, right top, from(#25aae1), color-stop(#4481eb), color-stop(#04befe), to(#3f86ed));
    background-image: -webkit-linear-gradient(left, #25aae1, #4481eb, #04befe, #3f86ed);
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    text-align: center;
}

.home-container .sectionContact  a:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}



/*** SERVICES **/
.home-container.services .header-content{
  padding : 40px;
  height: 20vh;
  background: rgba(255, 255, 255, 0.35);
}

.home-container.services .body-content{
  margin-top : 20px;
  margin-bottom : 20px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.35);
}

.home-container.services .body-content .block-step{
  text-align: center;
  height: 220px;
  border: rgb(7, 145, 196, 0.5) solid 0.1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  display : table;
  width: 100%;
  -webkit-transition: box-shadow .5s;
  transition: box-shadow .5s;
}

.home-container.services .body-content .block-step div{
  display: table-cell;
  vertical-align: middle;
}


.home-container.services .body-content .block-step h2, .home-container.services .body-content a{
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(0, 135, 233);
  text-decoration: none;
  height: 80px;
}

 .home-container.services .body-content .block-step:hover{
   -webkit-transform: scale(1.01) ;
           transform: scale(1.01) ;
   box-shadow: 0 0 11px rgba(33,33,33,.2);
 }

.home-container.services .body-content .block-step img{
  height: 160px;
}


@media (max-width:1158px) {
  .home-container .section2-container .howto-div{
    width: 100%;
  }

  .home-container .section4-container .section4-buttons{
    width: 100%;
  }

  .home-container .section3-container .slideContent{
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }

  .home-container .section3-container .slideContent .slide-text{
    font-style: italic;
    padding: 20px;
    padding-top: 70px;
  }
}

@media (max-width:800px) {
  .home-container .header .header-left h1{
      font-size: 70px;
      line-height: 1;
      color: white;
  }

  .home-container .header .header-left .Iam {
    font: normal 30px/40px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  }

  .home-container .header .header-left .Iam p {
    height: 40px;
  }

  .home-container .header .header-left .Iam b {
    height: 40px;
    padding-left: 0px;
  }

  @keyframes move{
  0%  { top: 0px; }
  20% { top: -40px; }
  40% { top: -80px; }
  60% { top: -120px; }
  80% { top: -160px; }
  }

  @-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }
  @keyframes move {
    0%  { top: 0px; }
    20% { top: -40px; }
    40% { top: -80px; }
    60% { top: -120px; }
    80% { top: -160px; }
  }

  .home-container.services .body-content{
    display: inline-block;
    height: 100%;
  }

  .home-container.services .body-content .block-step h2{
      font-size: 1.4rem;
      height: 50px;
  }
  .home-container.services .body-content .block-step{
    margin:  10px;
    height: 100%;
  }

}

@media (max-width:768px) {



  .home-container .section2-container .home-services-list {
    width: 100%;
    display: inline-block;
  }

  .home-container .section.section2{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home-container .section2-container .howto-div .card-title{
      height: auto;
  }
  .home-container .section2-container .howto-div{
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-row-gap: 60px;
  }

  .home-container .section2-container .howto-div button{
    display: none;
  }


  .home-container .section3-container .slideContent .slide-text{
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    text-align: right;
    padding: 50px;
  }
}
@media (max-width:660px) {
  .home-container .section3-container .slideContent{
    display: inline-block;
  }

  .home-container .section3-container .slideContent .slide-text{
    text-align: center;
  }

  .home-container .section3-container .slideContent .slide-img{
    display: none;
  }

  .text-danger.carousel-caption.d-none.d-md-block{
    display: block!important;
  }


}
@media (max-width:600px) {
  .home-container .section4-container .section4-buttons{
    width: 90%;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-row-gap: 20px;
  }

  .home-container svg{
    display: none;
  }
}
@media (max-width: 495px) {
  .home-container .header{
    min-height: 50vh;
  }
  .home-container .header .header-left h1{
      font-size: 30px;
      line-height: 1;
      color: white;
  }

  .home-container .header .header-left .Iam {
    font: normal 20px/30px "Apercu Pro","Helvetica Neue",Helvetica,sans-serif;
  }

  .home-container .header .header-left .Iam p {
    height: 30px;
  }

  .home-container .header .header-left .Iam b {
    height: 30px;
  }

  @keyframes move{
  0%  { top: 0px; }
  20% { top: -30px; }
  40% { top: -60px; }
  60% { top: -90px; }
  80% { top: -120px; }
  }

  @-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }
  @keyframes move {
    0%  { top: 0px; }
    20% { top: -30px; }
    40% { top: -60px; }
    60% { top: -90px; }
    80% { top: -120px; }
  }

  .home-container .header .cta-button button{
    min-width: 100px;
    font-weight: 500;
    font-stretch: normal;
    font-size: 15px;
  }
}

.my-account-main-container{
  width: 100%;
  min-height: 80vh;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.my-account-content{
  margin: 0 auto;
  padding : 50px;
  color: white;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
}

.my-account-content .my-infos{
  text-align: center;
  width: 80%;
  max-width: 850px;
  margin: 0 auto;
  margin-top : 50px;
  padding: 20px;
  padding-top: 50px;
  border-radius: 30px;
  background-color: white;
  color: black;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.51);
}

.my-account-content .my-infos label{
  font-weight: bold;
}

.my-account-content .my-infos .btn{
  width: 250px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 40px;
}


.my-account-content .my-infos .multiple-buttons{
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.my-account-content .register-link{
  text-decoration: none;
  color: white;
}
.lg-form .form-group .field-error.form-control {
  border: 1px red solid;
}

.my-infos-content{
  display: none;
}

.my-infos-content.active{
  display: block;
}

.my-account-content .scores-history .history-chart{

}

.my-account-content .scores-history .values{
  margin-top : 40px;
}

.my-account-content .nav-item:hover{
  cursor: pointer;
}

.my-account-content .active.nav-link:hover{
  color: black;
}



.services-souscrits .table-wrapper{
    margin: 10px 70px 70px;
    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.services-souscrits .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.services-souscrits .fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.services-souscrits .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.services-souscrits .fl-table thead th {
    color: #ffffff;
    background: #4fabc3;
}


.services-souscrits .fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}

.services-souscrits .fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
    .services-souscrits .fl-table {
        display: block;
        width: 100%;
    }
    .services-souscrits .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .services-souscrits .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .services-souscrits .fl-table thead th:last-child{
        border-bottom: none;
    }
    .services-souscrits .fl-table thead {
        float: left;
    }
    .services-souscrits .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .services-souscrits .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .services-souscrits .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .services-souscrits .fl-table tbody tr {
        display: table-cell;
    }
    .services-souscrits .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .services-souscrits .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .services-souscrits .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }
    .services-souscrits .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }
    .services-souscrits .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

@media (max-width:800px) {
  .my-account-content{
    padding-left: 0px;
    padding-right: 0px;
  }
  .my-account-content .my-infos .multiple-buttons{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .my-account-content .my-infos{
    width: 100%;
    border-radius: 0px;
  }


}

.main-404-container{
    height: 100vh;
    text-align: center;
    padding: 40px;
}

.main-404-container img {
  max-width: 100%;
  height: 200px;
}

.login-page-content{
  width: 100%;
  min-height: 80vh;
  background: #6dd5ed;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.register-page-content{
  width: 100%;
  min-height: 80vh;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding-bottom: 50px;
}

.page-content{
  width: 60%;
  margin: 0 auto;
  padding-top : 50px;
  color: white;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
}

.page-content .content-login{
  padding-bottom: 100px;
}

.page-content .lg-form{
  text-align: center;
  width: 30%;
  min-width: 350px;
  margin: 0 auto;
  margin-top : 50px;
  padding: 20px;
  padding-top: 50px;
  border-radius: 30px;
  background-color: white;
  color: black;
}
.page-content .lg-form a{
  text-decoration: none;
}

.page-content .mdp-forget{
    background: transparent;
    border: transparent;
    color : white;
    font-weight: lighter;
}

.page-content .lg-form .roundblue{
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 40px;
  background: white;
  border: 0.05rem solid #008cde;
  color: #008cde;
}

.page-content .lg-form .roundblue:hover{
  border: 0.05rem solid #008cde;
  background: #008cde;
  color: white;
}

.page-content .lg-form .fb-login button{
  height: 60px;
  font-size: 0.7rem;
  box-shadow: none;
  width: 100%;
}

.page-content .register-link{
  text-decoration: none;
  color: white;
}
.lg-form .form-group .field-error.form-control {
  border: 1px red solid;
}
.lg-form  input#cgucheck{
  margin: .4rem;
}

.lg-form .cgu-text{
  width: 90%;
  margin: 0 auto;
  color: black;
  text-align: justify;
  font-size: 0.8rem;
}
.lg-form .cgu-text.field-error,.lg-form .cgu-text.field-error a{
  color: #dc3545;
}

.lg-form .custom-switch{
  position: relative;
}
.lg-form .custom-control-label{
  color: black;
}
@media (max-width:767px) {
  .page-content{
    width: 100%;
  }
  .page-content .lg-form{
    width: 50%;
  }

  .page-content h2{
    padding-left: 5px;
    padding-right: 5px;

  }
}

.legal-page-content{
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.legal-content{
  width: 80%;
  margin: 0 auto;
  padding: 50px;
  background: rgba(255, 255, 255, 0.7);
  text-align: justify;
}

.legal-page-content h1{
  text-align: center;
  color:  #2193b0;
}

.legal-page-content h2{
  font-size: 1.4em;
  margin: 50px;
}

@media (max-width:850px) {
  .legal-content{
    width: 100%;
    padding: 20px;
  }

  .legal-page-content h2{
    margin: 20px;
  }
}

.offline-content{
  height: 100vh;
}

.preparer-entretien-main-container{
  width: 100%;
  text-align: center;
}

.preparer-entretien-main-container .preparer-content-header{
  padding: 40px;
}
.preparer-entretien-main-container .preparer-content-description{
  padding: 50px;
  display: grid;
  grid-template-columns: 8fr 1fr 10fr;
  grid-column-gap: 10px;
}

.preparer-entretien-main-container .top-image img{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.preparer-entretien-main-container .preparer-content-description .preparer-content-page-title{
  color: #3d92ca;
  margin-top : 20px;
}

.preparer-entretien-main-container .preparer-content-description .preparer-content-chapitres{
  margin-top: 20px;
  text-align: left;
  font-size: 1.2rem;
}
.preparer-entretien-main-container .preparer-content-description .red-line{
  border-left: 6px solid red;
}


.qcm-reminder{
  max-width: 30%;
  min-width: 300px;

  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width:770px) {
    .preparer-entretien-main-container .preparer-content-description{
      display: inline-block;
    }

    .preparer-entretien-main-container .preparer-content-description h1{
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
    .preparer-entretien-main-container .preparer-content-description .preparer-content-chapitres{
      font-size: 1rem;
    }

    .preparer-entretien-main-container .preparer-content-description .red-line{
      border-top: 2px solid red;

    }
}

.deroulement-main-container{
  width: 100%;
  text-align: center;
}

.deroulement-content p{
  text-align: justify;
}

.deroulement-main-container nav{
  width: 100%;
  position: fixed;
}

.deroulement-main-container .top-image{
  padding-top: 70px;
  /*background-image: url('../img/deroulement.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  min-height: 200px;
}

.deroulement-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.deroulement-main-container .top-image-title h1{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.deroulement-main-container .top-image-title h1 span{
  color: #cf3030;
}


.deroulement-main-container .deroulement-content-header{
  width: 80%;
  margin : 0 auto;
  padding: 40px;
}
.deroulement-main-container .deroulement-content-header p{
  text-align: center;
}

.deroulement-main-container .deroulement-content-description{
  padding: 50px;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-column-gap: 10px;
}

.deroulement-main-container .deroulement-top-image img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.deroulement-section{
  margin-top: 20px;
}

.deroulement-section h2{
  margin-bottom: 40px;
}

.deroulement-section h3{
  color:  #3d92ca;
}

.deroulement-section.section-1-container{
  width: 90%;
  min-width: 800px;
  margin: 0 auto;
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
  text-align: left;
  background-image: url(/static/media/meeting-1.6cbdbdaa.jpg);
  background-position: left;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin-bottom: 40px;
}

.deroulement-section.section-1-right{
  padding: 50px;
  background: rgba(255, 255, 255, 0.85);
}

.deroulement-section.section-1-bottom-left{
  padding: 50px;
  background: rgba(255, 255, 255, 0.85);
}

.deroulement-section.section-end{
  margin: 50px;
}


@media (max-width:900px) {
  .deroulement-section.section-1-container{
    display: inline-block;
    min-width: inherit;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .deroulement-main-container .deroulement-content-header{
    width: 100%;
  }
}

.portfolio-experiment {
  background: rgba(45, 45, 45, 0);
  text-align: center; }
  .portfolio-experiment a {
    color: white;
    padding: 0.7em calc(0.7em * 1.2);
    display: inline-block;
    border: 3px solid transparent;
    position: relative;
    font-size: 1.5em;
    cursor: pointer;
    letter-spacing: 0.07em; }
    .portfolio-experiment a .text {
      color: #cf3030;
      font-family: proxima-nova, monospace;
      -webkit-transform: translate3d(0, 0.7em, 0);
              transform: translate3d(0, 0.7em, 0);
      display: block;
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: -webkit-transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s, -webkit-transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s; }
    .portfolio-experiment a:after {
      position: absolute;
      content: '';
      bottom: -3px;
      left: calc(0.7em * 1.2);
      right: calc(0.7em * 1.2);
      height: 3px;
      background: #cf3030;
      -webkit-transition: right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, -webkit-transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s;
      transition: right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, -webkit-transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s;
      transition: transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s, right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s;
      transition: transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s, right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, -webkit-transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s;
      -webkit-transform-origin: left;
              transform-origin: left; }
  .portfolio-experiment .line {
    position: absolute;
    background: #cf3030; }
    .portfolio-experiment .line.-right, .portfolio-experiment .line.-left {
      width: 3px;
      bottom: -3px;
      top: -3px;
      -webkit-transform: scale3d(1, 0, 1);
              transform: scale3d(1, 0, 1); }
    .portfolio-experiment .line.-top, .portfolio-experiment .line.-bottom {
      height: 3px;
      left: -3px;
      right: -3px;
      -webkit-transform: scale3d(0, 1, 1);
              transform: scale3d(0, 1, 1); }
    .portfolio-experiment .line.-right {
      right: -3px;
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      transition: -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s, -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
      -webkit-transform-origin: top;
              transform-origin: top; }
    .portfolio-experiment .line.-top {
      top: -3px;
      -webkit-transition: -webkit-transform 0.08s linear 0.43s;
      transition: -webkit-transform 0.08s linear 0.43s;
      transition: transform 0.08s linear 0.43s;
      transition: transform 0.08s linear 0.43s, -webkit-transform 0.08s linear 0.43s;
      -webkit-transform-origin: left;
              transform-origin: left; }
    .portfolio-experiment .line.-left {
      left: -3px;
      -webkit-transition: -webkit-transform 0.08s linear 0.51s;
      transition: -webkit-transform 0.08s linear 0.51s;
      transition: transform 0.08s linear 0.51s;
      transition: transform 0.08s linear 0.51s, -webkit-transform 0.08s linear 0.51s;
      -webkit-transform-origin: bottom;
              transform-origin: bottom; }
    .portfolio-experiment .line.-bottom {
      bottom: -3px;
      -webkit-transition: -webkit-transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      transition: -webkit-transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      transition: transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      transition: transform 0.3s cubic-bezier(1, 0, 0.65, 1.01), -webkit-transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
      -webkit-transform-origin: right;
              transform-origin: right; }
  .portfolio-experiment a:hover,
  .portfolio-experiment a:active {
    text-decoration: none; }
    .portfolio-experiment a:hover .text,
    .portfolio-experiment a:active .text {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: -webkit-transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      transition: transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s, -webkit-transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s; }
    .portfolio-experiment a:hover:after,
    .portfolio-experiment a:active:after {
      -webkit-transform: scale3d(0, 1, 1);
              transform: scale3d(0, 1, 1);
      right: -3px;
      left: -3px;
      -webkit-transform-origin: right;
              transform-origin: right;
      -webkit-transition: right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s, -webkit-transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s;
      transition: right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s, -webkit-transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s;
      transition: transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s, right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s;
      transition: transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s, right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s, -webkit-transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s; }
    .portfolio-experiment a:hover .line,
    .portfolio-experiment a:active .line {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1); }
      .portfolio-experiment a:hover .line.-right,
      .portfolio-experiment a:active .line.-right {
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        transition: -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s, -webkit-transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
        -webkit-transform-origin: bottom;
                transform-origin: bottom; }
      .portfolio-experiment a:hover .line.-top,
      .portfolio-experiment a:active .line.-top {
        -webkit-transition: -webkit-transform 0.08s linear 0.4s;
        transition: -webkit-transform 0.08s linear 0.4s;
        transition: transform 0.08s linear 0.4s;
        transition: transform 0.08s linear 0.4s, -webkit-transform 0.08s linear 0.4s;
        -webkit-transform-origin: right;
                transform-origin: right; }
      .portfolio-experiment a:hover .line.-left,
      .portfolio-experiment a:active .line.-left {
        -webkit-transition: -webkit-transform 0.08s linear 0.48s;
        transition: -webkit-transform 0.08s linear 0.48s;
        transition: transform 0.08s linear 0.48s;
        transition: transform 0.08s linear 0.48s, -webkit-transform 0.08s linear 0.48s;
        -webkit-transform-origin: top;
                transform-origin: top; }
      .portfolio-experiment a:hover .line.-bottom,
      .portfolio-experiment a:active .line.-bottom {
        -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        transition: -webkit-transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        transition: transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        transition: transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s, -webkit-transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
        -webkit-transform-origin: left;
                transform-origin: left; }

.livret-main-container{
  width: 100%;
  text-align: center;
}

.livret-main-container nav{
  width: 100%;
  position: fixed;
  z-index: 50;
}

.livret-main-container .top-image{
  padding-top: 70px;
  background-image: url(/static/media/livret.457e0d77.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.livret-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.livret-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.livret-main-container .top-image-title h2 span{
  color: #cf3030;
}

.livret-content-header{
    width: 80%;
    margin: 0 auto;
    padding-top : 50px;
}

.livret-content-header p{
  width: 90%;
  margin: 0 auto;
  text-align: justify;
}

.livret-content img{
  width: auto;
  height: 100%;
  max-height: 200px;
  margin: 0 auto;
}

.livret-content .chapitres-liste .chapitre-menu-toggle{
  position: relative;
  width: 320px;
  height: 25px;
  margin-top: 20px;
  font-size: 0.7rem;
}

.livret-section{
  margin: 50px;
}

.livret-section h3{
  text-align: center;
  text-decoration: underline;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  margin-bottom: 20px;
}

.livret-section.section-1 p{
  margin-top: 100px;
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.livret-section.section-2 p{
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}

.livret-section.section-4 img{
  margin-top: 40px;
  width: 100%;
  min-height: 500px;
  object-fit: scale-down;
}

.livret-section.section-end{
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  font-size: 0.8em;
}


.livret-section.section-1 .effect.quoting-focus{
  text-align: center;
}

.effect.quoting-focus{
    color: black;
    flex: 1 1;
    text-decoration: none;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, yellow), to(yellow));
    background-image: -webkit-linear-gradient(left, yellow 0, yellow 100%);
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
    background-position: 0 88%;
    background-size: 100% 0.2em;
    background-repeat: no-repeat;
    -webkit-transition: background-size 0.25s ease-in;
    transition: background-size 0.25s ease-in;
  }

.effect.quoting-focus:hover{
  background-size: 100% 88%;
}

/****** DEMOCRATIE *******/
.livret-section.section-2 .blocks-section-pouvoirs{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.blocks-section-pouvoirs .block-section-pouvoir{
  padding: 20px;
  width: 100%;
  min-height: 500px;
  background: #e0e0e0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #e0e0e0, #ffffff, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left bottom, left top, from(#e0e0e0), color-stop(#ffffff), to(#ffffff));
  background: -webkit-linear-gradient(bottom, #e0e0e0, #ffffff, #ffffff);
  background: linear-gradient(to top, #e0e0e0, #ffffff, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.blocks-section-pouvoirs .block-section-pouvoir img{
  max-width: 80px;
  max-height: 80px;
  margin: 10px;
}

.blocks-section-pouvoirs .block-section-pouvoir-title{
  margin: 20px;
}

.livret-section.section3-droits-devoirs{
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
}

/****** TERRITOIRE *******/

.livret-section-maps{
  color: black;
  display: grid;
  grid-row-gap: 10px;
  margin: 0 auto;
  background: url(/static/media/carteFrance.0f807746.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
}

.livret-section-maps.outremer{
  margin-top: 50px;
  color: black;
  display: grid;
  grid-template-rows: repeat(2,1fr);
  grid-row-gap: 10px;
  margin: 0 auto;
  background: url(/static/media/domtom.e9669584.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.livret-section-map{
  background: rgba(255, 255, 255, 0.5);
  width: 60%;
  border-bottom: black dotted 0.5px;
  padding: 20px;
  text-align: left;
}

.livret-section-maps.outremer .livret-section-map{
  margin-right: 0;
  margin-left: auto;
}








.livret-content .chapitres{
  width: 80%;
  margin: 0 auto;
}
.livret-content .chapitres h3{
  font-size: 1.65rem;
  margin: 15px 0;
  text-align: center;
}
.livret-content .chapitres ol{
  counter-reset: section;
}
.livret-content .chapitres li {
  list-style-type: none;
  font-size: 1.5rem;
  padding: 15px;
  margin-bottom: 15px;
  background: rgb(0, 141, 210);
  color: rgb(245, 233, 95);
  -webkit-transition: background-color 0.9s ease;
  transition: background-color 0.9s ease;
}
.livret-content .chapitres li:hover {
  background: rgb(0, 178, 255);
}
.livret-content .chapitres i{
  color: white;
  padding-left: 0.5rem;
}
.livret-content .chapitres h4 {
  position: relative;
  padding-bottom: 10px;
  color: white;
}
.livret-content .chapitres h4:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 2px;
  background: white;
}
.livret-content .chapitres p {
  font-size: .9rem;
  line-height: 1.4rem;
  margin-top: 15px;
}
.livret-content .chapitres li::before {
    counter-increment: section;
    content: counter(section);
    text-align: center;
    display: inline-block;
    color: #000;
    border-radius: 50%;
    position: absolute;
    left: -65px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 12px;
    font-size: 2rem;
    width: 25px;
    height: 25px;
    border: 2px solid #000;
}

@media (max-width:900px) {
  .livret-section.section-2 .blocks-section-pouvoirs{
    display: inline-block;
  }

  .livret-section.section-2 .block-section-pouvoir{
    min-height: 100px;
    margin: 0;
    margin-bottom: 40px;
  }

  .livret-section-map{
    width: 90%;
  }
}

@media (max-width:700px) {
  .livret-section  {
    margin: 15px;
  }
  .livret-section-map.communes{
    display: inline-block;
  }
  .livret-section-map.communes img{
    margin-top: 20px;
  }

  .livret-section.section-1 .livret-section-maps{
    width: 100%;
  }

  .livret-section.section3-droits-devoirs{
    display: inline-block;
  }

  .livret-main-container .breadcrumb{
    display: none;
  }
}

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: #fff;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0; }
  .blog-card a {
    color: inherit; }
    .blog-card a:hover {
      color: #3ca8d6; }
  .blog-card:hover .photo {
    -webkit-transform: scale(1.3) rotate(3deg);
            transform: scale(1.3) rotate(3deg); }
  .blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px; }
  .blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; }
  .blog-card .details,
  .blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none; }
  .blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    -webkit-transition: left .2s;
    transition: left .2s;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: .9rem; }
    .blog-card .details a {
      -webkit-text-decoration: dotted underline;
              text-decoration: dotted underline; }
    .blog-card .details ul li {
      display: inline-block; }
    .blog-card .details .author:before {
      font-family: FontAwesome;
      margin-right: 10px;
      content: "\f007"; }
    .blog-card .details .date:before {
      font-family: FontAwesome;
      margin-right: 10px;
      content: "\f133"; }
    .blog-card .details .tags ul:before {
      font-family: FontAwesome;
      content: "\f02b";
      margin-right: 10px; }
    .blog-card .details .tags li {
      margin-right: 2px; }
      .blog-card .details .tags li:first-child {
        margin-left: -4px; }
  .blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1; }
    .blog-card .description h1,
    .blog-card .description h2 {
      font-family: Poppins, sans-serif; }
    .blog-card .description h1 {
      line-height: 1;
      margin: 0;
      font-size: 1.7rem; }
    .blog-card .description h2 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: #a2a2a2;
      margin-top: 5px; }
    .blog-card .description .read-more {
      text-align: right; }
      .blog-card .description .read-more a {
        color: #3ca8d6;
        display: inline-block;
        position: relative; }
        .blog-card .description .read-more a:after {
          content: ">";
          font-family: "fa5-proxima-nova";
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          -webkit-transition: margin .3s, opacity .3s;
          transition: margin .3s, opacity .3s; }
        .blog-card .description .read-more a:hover:after {
          margin-left: 5px;
          opacity: 1; }
  .blog-card p {
    text-align: left;
    position: relative;
    margin: 1rem 0 0; }
    .blog-card p:first-of-type {
      margin-top: 1.25rem; }
      .blog-card p:first-of-type:before {
        content: "";
        position: absolute;
        height: 5px;
        background: #3ca8d6;
        width: 35px;
        top: -0.75rem;
        border-radius: 3px; }
  .blog-card:hover .details {
    left: 0%; }
  .blog-card .description li {
    text-align: left; }
  @media (min-width: 640px) {
    .blog-card {
      flex-direction: row;
      max-width: 900px; }
      .blog-card .meta {
        flex-basis: 40%;
        height: auto; }
      .blog-card .description {
        flex-basis: 60%; }
        .blog-card .description:before {
          -webkit-transform: skewX(-3deg);
                  transform: skewX(-3deg);
          content: "";
          background: #fff;
          width: 30px;
          position: absolute;
          left: -10px;
          top: 0;
          bottom: 0;
          z-index: -1; }
      .blog-card.alt {
        flex-direction: row-reverse; }
        .blog-card.alt .description:before {
          left: inherit;
          right: -10px;
          -webkit-transform: skew(3deg);
                  transform: skew(3deg); }
        .blog-card.alt .details {
          padding-left: 25px; } }

.photo.photo-drapeau {
  background-image: url(/static/media/card-1.40c5ff0a.jpg); }

.photo.photo-marianne {
  background-image: url(/static/media/card-2.1071991c.jpg); }

.photo.photo-14juillet {
  background-image: url(/static/media/card-3.498ec1b2.jpg); }

.photo.photo-devise {
  background-image: url(/static/media/card-4.a11c271d.jpg); }

.photo.photo-coq {
  background-image: url(/static/media/card-5.fa930470.jpg); }

.photo.photo-marseillaise {
  background-image: url(/static/media/card-6.2dd34ce8.jpg); }

.photo.photo-faisceau {
  background-image: url(/static/media/card-7.bacaafb4.png); }

@charset "UTF-8";
.chapitres-liste .chapitre-menu-close {
  opacity: 0;
  content: '×';
  color: white;
  position: relative;
  top: 20%;
  margin: 0;
  left: -4px;
  font-family: Arial, sans-serif;
  font-size: 76px;
  line-height: 0;
  -webkit-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
  opacity: 0.6;
  background-color: transparent;
  z-index: 2;
  border: none;
  outline: none;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }
  .chapitres-liste .chapitre-menu-close:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    right: auto;
    width: 100%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, transparent));
    background: -webkit-linear-gradient(top, transparent 20%);
    background: linear-gradient(to bottom, transparent 20%);
    -webkit-transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out;
    transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out; }
  .chapitres-liste .chapitre-menu-close:active {
    -webkit-transform: translateY(2px);
            transform: translateY(2px); }
  .chapitres-liste .chapitre-menu-close:hover {
    opacity: 1; }
  .open .chapitres-liste .chapitre-menu-close {
    opacity: 1; }
    .open .chapitres-liste .chapitre-menu-close:before {
      opacity: 0;
      width: 0; }
    .open .chapitres-liste .chapitre-menu-close:after {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) rotate(360deg);
              transform: translate3d(0, 0, 0) rotate(360deg);
      -webkit-transition: opacity 0.4s 1s ease-out, -webkit-transform 0.4s 1s ease-out;
      transition: opacity 0.4s 1s ease-out, -webkit-transform 0.4s 1s ease-out;
      transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out;
      transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out, -webkit-transform 0.4s 1s ease-out; }

.chapitres-liste ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .chapitres-liste ul li {
    cursor: pointer;
    padding: 6px 20px;
    font-size: 20px; }
  .chapitres-liste ul a {
    color: #6cc6ff; }

.chapitres-liste .chapitre-menu-toggle {
  font-family: sans-serif;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  background-color: #007bff;
  border-radius: 5rem;
  box-shadow: 1px 2.9px 16px rgba(27, 139, 249, 0.4);
  -webkit-transition: 0.6s cubic-bezier(0.01, 1.69, 0.99, 0.94);
  transition: 0.6s cubic-bezier(0.01, 1.69, 0.99, 0.94); }
  .chapitres-liste .chapitre-menu-toggle:hover {
    box-shadow: 3px 4.9px 16px rgba(27, 139, 249, 0.6);
    letter-spacing: 5px; }

.chapitres-liste nav {
  z-index: 50;
  position: fixed;
  top: -150%;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden; }
  .chapitres-liste nav:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0, 98, 204, 0.98);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    -webkit-transform: scale(0.04), translateY(9999px);
            transform: scale(0.04), translateY(9999px);
    overflow: hidden; }
  .open .chapitres-liste nav {
    top: 0; }
    .open .chapitres-liste nav:before {
      -webkit-animation: menu-animation 0.8s ease-out forwards;
              animation: menu-animation 0.8s ease-out forwards; }

.chapitres-liste ul.menu {
  width: 90%;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000;
          perspective: 1000;
  color: white; }
  .chapitres-liste ul.menu li {
    opacity: 0;
    text-align: center;
    -webkit-transform: translate3d(0, 36px, 0);
            transform: translate3d(0, 36px, 0); }
    .chapitres-liste ul.menu li:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      left: auto;
      background-color: white;
      height: 100%;
      width: 0;
      overflow: hidden;
      -webkit-transition: width 0.14s ease-out;
      transition: width 0.14s ease-out; }
    .chapitres-liste ul.menu li:after {
      opacity: 0;
      content: attr(data-text);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      color: #007bff;
      overflow: hidden;
      -webkit-transform: translate(-24px, 6px);
              transform: translate(-24px, 6px);
      -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
      transition: opacity 0.1s ease-out, -webkit-transform 0.1s ease-out;
      transition: transform 0.1s ease-out, opacity 0.1s ease-out;
      transition: transform 0.1s ease-out, opacity 0.1s ease-out, -webkit-transform 0.1s ease-out; }
    .chapitres-liste ul.menu li:hover:before {
      left: 0;
      right: auto;
      width: 100%; }
    .chapitres-liste ul.menu li:hover:after {
      opacity: 1;
      padding: 0 20px;
      -webkit-transform: translate(0px, 6px);
              transform: translate(0px, 6px);
      -webkit-transition: opacity 0.2s 0.14s ease-out, -webkit-transform 0.2s 0.14s ease-out;
      transition: opacity 0.2s 0.14s ease-out, -webkit-transform 0.2s 0.14s ease-out;
      transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out;
      transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out, -webkit-transform 0.2s 0.14s ease-out; }
    .open .chapitres-liste ul.menu li {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out; }
      .open .chapitres-liste ul.menu li:nth-child(1) {
        -webkit-transition-delay: 0.75s;
                transition-delay: 0.75s; }
      .open .chapitres-liste ul.menu li:nth-child(2) {
        -webkit-transition-delay: 0.85s;
                transition-delay: 0.85s; }
      .open .chapitres-liste ul.menu li:nth-child(3) {
        -webkit-transition-delay: 0.95s;
                transition-delay: 0.95s; }
      .open .chapitres-liste ul.menu li:nth-child(4) {
        -webkit-transition-delay: 1.05s;
                transition-delay: 1.05s; }

.chapitre-menu-toggle.hidden {
  display: none; }

@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
            transform: scale(0.04) translateY(300%); }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
            transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out; }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
            transform: scale(0.04) translateY(0); }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
            transform: scale(0.02) translateY(0px); }
  61% {
    -webkit-transform: scale(0.04);
            transform: scale(0.04); }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0; } }

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
            transform: scale(0.04) translateY(300%); }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
            transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out; }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
            transform: scale(0.04) translateY(0); }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
            transform: scale(0.02) translateY(0px); }
  61% {
    -webkit-transform: scale(0.04);
            transform: scale(0.04); }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0; } }

@media (max-width: 700px) {
  .chapitres-liste .chapitre-menu-close {
    position: absolute;
    top: 15%;
    left: 10px;
    right: auto;
    font-size: 40px; }
  .chapitres-liste ul li {
    font-size: 15px; } }

.slider {
  overflow: hidden;
  position: relative;
  height: 100vh;
  color: #fff; }
  .slider__top-heading {
    z-index: 12;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    padding-left: 10px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    -webkit-transition: all 0.5s 1s;
    transition: all 0.5s 1s;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0; }
    .slider.s--ready .slider__top-heading {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1; }
  .slider__slides {
    position: relative;
    height: 100%; }
  .slider__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .slider__slide.s--active {
      pointer-events: auto; }
    .slider__slide-content {
      width: 80%;
      margin: 0 auto;
      z-index: 6;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      text-transform: uppercase;
      line-height: 1; }
    .slider__slide-subheading {
      margin-bottom: 20px;
      font-size: 24px;
      letter-spacing: 2px;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      opacity: 0;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
      .slider__slide.s--active .slider__slide-subheading {
        -webkit-transition-delay: 0.65s;
                transition-delay: 0.65s;
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
    .slider__slide-heading {
      display: flex;
      margin-bottom: 20px;
      font-size: 60px;
      letter-spacing: 12px; }
      .slider__slide-heading span {
        display: block;
        opacity: 0;
        -webkit-transform: translateY(-60px);
                transform: translateY(-60px);
        -webkit-transition: all 0.33333s;
        transition: all 0.33333s; }
        .slider__slide.s--prev .slider__slide-heading span {
          -webkit-transform: translateY(60px);
                  transform: translateY(60px); }
        .slider__slide.s--active .slider__slide-heading span {
          opacity: 1;
          -webkit-transform: translateY(0);
                  transform: translateY(0); }
        .slider__slide-heading span:nth-child(1) {
          -webkit-transition-delay: 0s;
                  transition-delay: 0s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(1) {
            -webkit-transition-delay: 0.33333s;
                    transition-delay: 0.33333s; }
        .slider__slide-heading span:nth-child(2) {
          -webkit-transition-delay: 0.1s;
                  transition-delay: 0.1s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(2) {
            -webkit-transition-delay: 0.43333s;
                    transition-delay: 0.43333s; }
        .slider__slide-heading span:nth-child(3) {
          -webkit-transition-delay: 0.2s;
                  transition-delay: 0.2s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(3) {
            -webkit-transition-delay: 0.53333s;
                    transition-delay: 0.53333s; }
        .slider__slide-heading span:nth-child(4) {
          -webkit-transition-delay: 0.3s;
                  transition-delay: 0.3s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(4) {
            -webkit-transition-delay: 0.63333s;
                    transition-delay: 0.63333s; }
        .slider__slide-heading span:nth-child(5) {
          -webkit-transition-delay: 0.4s;
                  transition-delay: 0.4s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(5) {
            -webkit-transition-delay: 0.73333s;
                    transition-delay: 0.73333s; }
        .slider__slide-heading span:nth-child(6) {
          -webkit-transition-delay: 0.5s;
                  transition-delay: 0.5s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(6) {
            -webkit-transition-delay: 0.83333s;
                    transition-delay: 0.83333s; }
        .slider__slide-heading span:nth-child(n+7) {
          -webkit-transition-delay: 0.6s;
                  transition-delay: 0.6s; }
          .slider__slide.s--active .slider__slide-heading span:nth-child(n+7) {
            -webkit-transition-delay: 0.93333s;
                    transition-delay: 0.93333s; }
    .slider__slide-readmore {
      position: relative;
      font-size: 1.5rem;
      text-transform: none;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
      .slider__slide.s--active .slider__slide-readmore {
        -webkit-transition-delay: 0.65s;
                transition-delay: 0.65s;
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0); }
      .slider__slide-readmore:before {
        content: '';
        position: absolute;
        left: -2px;
        top: -3px;
        width: calc(100% + 4px);
        height: calc(100% + 6px);
        background: rgba(255, 255, 255, 0.4);
        -webkit-transform: scaleX(0.3);
                transform: scaleX(0.3);
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s; }
      .slider__slide-readmore:hover:before {
        -webkit-transform: scaleX(1);
                transform: scaleX(1); }
    .slider__slide-parts {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%; }
      .slider__slide-parts:after {
        content: '';
        z-index: 5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0); }
    .slider__slide-part {
      position: relative;
      width: 25%;
      height: 100%; }
      .slider__slide-part-inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 0 0;
        background-repeat: no-repeat;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
        .slider__slide-part-inner:before {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          background-image: inherit;
          background-size: cover;
          background-position: center center;
          -webkit-transition: opacity 0.25s;
          transition: opacity 0.25s;
          opacity: 0; }
        .slider__slide-part:nth-child(1) .slider__slide-part-inner {
          z-index: 3;
          -webkit-transition-delay: 0.24s;
                  transition-delay: 0.24s;
          -webkit-transform: translateX(-32.5%);
                  transform: translateX(-32.5%); }
          .slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner {
            -webkit-transition-delay: 0.28s;
                    transition-delay: 0.28s; }
          .slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
            left: 0vw;
            -webkit-transition-delay: 0.365s;
                    transition-delay: 0.365s; }
            .slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
              -webkit-transition-delay: 0.28s;
                      transition-delay: 0.28s; }
        .slider__slide-part:nth-child(2) .slider__slide-part-inner {
          z-index: 2;
          -webkit-transition-delay: 0.16s;
                  transition-delay: 0.16s;
          -webkit-transform: translateX(-65%);
                  transform: translateX(-65%); }
          .slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner {
            -webkit-transition-delay: 0.36s;
                    transition-delay: 0.36s; }
          .slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
            left: -25vw;
            -webkit-transition-delay: 0.285s;
                    transition-delay: 0.285s; }
            .slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
              -webkit-transition-delay: 0.36s;
                      transition-delay: 0.36s; }
        .slider__slide-part:nth-child(3) .slider__slide-part-inner {
          z-index: 1;
          -webkit-transition-delay: 0.08s;
                  transition-delay: 0.08s;
          -webkit-transform: translateX(-97.5%);
                  transform: translateX(-97.5%); }
          .slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner {
            -webkit-transition-delay: 0.44s;
                    transition-delay: 0.44s; }
          .slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
            left: -50vw;
            -webkit-transition-delay: 0.205s;
                    transition-delay: 0.205s; }
            .slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
              -webkit-transition-delay: 0.44s;
                      transition-delay: 0.44s; }
        .slider__slide-part:nth-child(4) .slider__slide-part-inner {
          z-index: 0;
          -webkit-transition-delay: 0s;
                  transition-delay: 0s;
          -webkit-transform: translateX(-130%);
                  transform: translateX(-130%); }
          .slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner {
            -webkit-transition-delay: 0.52s;
                    transition-delay: 0.52s; }
          .slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
            left: -75vw;
            -webkit-transition-delay: 0.125s;
                    transition-delay: 0.125s; }
            .slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
              -webkit-transition-delay: 0.52s;
                      transition-delay: 0.52s; }
        .slider__slide.s--active .slider__slide-part-inner {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          -webkit-transition-timing-function: ease;
                  transition-timing-function: ease; }
          .slider__slide.s--active .slider__slide-part-inner:before {
            opacity: 1; }
  .slider__control {
    z-index: 40;
    position: absolute;
    left: 50px;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
    -webkit-transition: all 0.5s 1s;
    transition: all 0.5s 1s;
    cursor: pointer; }
    .slider__control:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
      border: 2px solid #000;
      border-bottom: none;
      border-right: none;
      -webkit-transform: translateX(5px) rotate(-45deg);
              transform: translateX(5px) rotate(-45deg); }
    .slider__control--right {
      left: auto;
      right: 50px;
      -webkit-transform: translateX(50px);
              transform: translateX(50px); }
      .slider__control--right:before {
        -webkit-transform: translateX(-5px) rotate(135deg);
                transform: translateX(-5px) rotate(135deg); }
    .slider.s--ready .slider__control {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1; }

.icon-link {
  z-index: 100;
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px; }
  .icon-link img {
    width: 100%;
    vertical-align: top; }
  .icon-link--twitter {
    left: auto;
    right: 5px; }

@media (max-width: 700px) {
  .livret-swipe-cards h2 {
    font-size: 1.5rem; }
  .livret-swipe-cards h3 {
    font-size: 1.2rem; }
  .livret-swipe-cards p {
    font-size: 1rem; }
  .livret-swipe-cards .slider__control {
    background: rgba(255, 255, 255, 0); }
    .livret-swipe-cards .slider__control--right {
      right: 0px; }
    .livret-swipe-cards .slider__control--left {
      left: 0px; } }

.timeline-wrapper {
  background: #7F7FD5;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #91EAE4, #91EAE4, #86A8E7, #007bff);
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#91EAE4), color-stop(#91EAE4), color-stop(#86A8E7), to(#007bff));
  background: -webkit-linear-gradient(top, #91EAE4, #91EAE4, #86A8E7, #007bff);
  background: linear-gradient(to bottom, #91EAE4, #91EAE4, #86A8E7, #007bff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.historyTL-component {
  margin-top: 20px; }

.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns {
  padding-top: 50px;
  margin-top: 0; }

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  text-align: left;
  left: 134% !important; }

.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
  right: 134% !important;
  left: auto !important; }

.vertical-timeline-element-content img {
  max-width: 100%;
  height: auto;
  box-shadow: 1px 5px 10px 2px #a1a1a1;
  margin-top: 20px;
  margin-bottom: 20px; }

.personaliteCards {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f4f4;
  font-size: 13px;
  font-family: 'Arvo', monospace; }
  @supports (display: grid) {
    .personaliteCards {
      display: block; } }
  .personaliteCards .message {
    border: 1px solid #d2d0d0;
    padding: 2em;
    font-size: 1.7vw;
    box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4); }
    @supports (display: grid) {
      .personaliteCards .message {
        display: none; } }
  .personaliteCards .section {
    display: none;
    padding: 2rem; }
    @media screen and (min-width: 768px) {
      .personaliteCards .section {
        padding: 4rem; } }
    @supports (display: grid) {
      .personaliteCards .section {
        display: block; } }
  .personaliteCards h1 {
    font-size: 2rem;
    margin: 0 0 1.5em; }
  .personaliteCards .grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 150px;
    grid-auto-flow: row dense; }
  .personaliteCards .item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #0c9a9a;
    color: #fff;
    grid-column-start: auto;
    grid-row-start: auto;
    color: #fff;
    background-size: cover;
    background-position: center;
    box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    cursor: pointer;
    counter-increment: item-counter;
    width: 100%; }
    .personaliteCards .item:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.3;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out; }
    .personaliteCards .item--medium {
      grid-row-end: span 2; }
    .personaliteCards .item--large {
      grid-row-end: span 3; }
    .personaliteCards .item--full {
      grid-column-end: auto; }
      @media screen and (min-width: 768px) {
        .personaliteCards .item--full {
          grid-column: 1/-1;
          grid-row-end: span 2; } }
    .personaliteCards .item__details {
      position: relative;
      z-index: 1;
      padding: 15px;
      color: #444;
      background: #fff;
      letter-spacing: 1px;
      color: #828282;
      -webkit-transition: opacity 0s ease-in-out;
      transition: opacity 0s ease-in-out; }
      .personaliteCards .item__details:before {
        font-weight: bold;
        font-size: 1.1rem;
        padding-right: 0.5em;
        color: #444; }
    .personaliteCards .item:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
      .personaliteCards .item:hover:after {
        opacity: 0; }
      .personaliteCards .item:hover .description {
        display: block;
        opacity: 1; }
      .personaliteCards .item:hover .item__details {
        opacity: 0; }
  .personaliteCards .description {
    display: flex;
    display: none;
    position: relative;
    z-index: 1;
    padding: 0;
    height: 50%;
    color: #444;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    letter-spacing: 1px;
    color: #828282;
    opacity: 0;
    -webkit-transition: opacity 3s ease;
    transition: opacity 3s ease; }
    .personaliteCards .description:before {
      font-weight: bold;
      font-size: 1.1rem;
      padding-right: 0.5em;
      color: #444; }

@media (max-width: 500px) {
  .personaliteCards .section {
    padding: 10px; }
  .personaliteCards .grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-auto-rows: 150px;
    grid-auto-flow: row dense; }
  .personaliteCards .item {
    font-size: 0.6rem; } }

.statistics {
  display: grid;
  grid-template-rows: repeat(1, minmax(100vh, auto)); }

.heading {
  background: white;
  height: 5%;
  margin: 10px;
  padding: 10px; }

.swiper-container {
  width: 100%;
  height: 90%; }

.swiper-slide {
  text-align: center;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center; }

.container-general {
  padding: 75px 0;
  margin: 0 auto;
  width: 100%; }
  .container-general .gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh; }
    .container-general .gallery-wrap .item {
      flex: 1 1;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: none;
      -webkit-transition: all 0.8s ease;
      transition: all 0.8s ease; }
      .container-general .gallery-wrap .item:hover, .container-general .gallery-wrap .item:active {
        flex: 7 1; }
        .container-general .gallery-wrap .item:hover .item-title, .container-general .gallery-wrap .item:active .item-title {
          display: block; }

.container-general .item-title {
  background: rgba(255, 255, 255, 0.5);
  font-size: 6rem;
  padding: 20px;
  padding-top: 20%;
  display: none; }
  .container-general .item-title h3 {
    text-decoration: none;
    font-weight: normal; }
  .container-general .item-title span {
    font-size: 8rem;
    font-weight: bolder; }
  .container-general .item-title p {
    font-size: 1rem;
    text-align: center; }

.wrap-effect-1 .item:first-of-type {
  background-image: url(/static/media/population.f1338f59.jpg); }

.wrap-effect-1 .item:nth-of-type(2) {
  background-image: url(/static/media/territoire.6e14742d.jpg); }

.wrap-effect-1 .item:nth-of-type(3) {
  background-image: url(/static/media/fleuve.374a9b2d.jpg); }

.wrap-effect-1 .item:nth-of-type(4) {
  background-image: url(/static/media/agglo.3cd1d8e1.jpg); }

.wrap-effect-1 .item:last-of-type {
  background-image: url(/static/media/metropole.076ee2ab.png); }

.wrap-effect-1 .item-title {
  color: white; }

.wrap-effect-2 .item:hover, .wrap-effect-2 .item:active {
  -webkit-transform: translate3d(0, 0, 100px);
          transform: translate3d(0, 0, 100px);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  -webkit-filter: saturate(2) contrast(120%);
          filter: saturate(2) contrast(120%); }

.wrap-effect-2 .item:first-of-type {
  background-image: url(/static/media/speaker.43aac939.jpg); }

.wrap-effect-2 .item:nth-of-type(2) {
  background-image: url(/static/media/Ktx3.6f807567.gif); }

.wrap-effect-2 .item:nth-of-type(3) {
  background-image: url(/static/media/yellow.f35317b2.jpeg); }

.wrap-effect-2 .item:nth-of-type(4) {
  background-image: url(/static/media/yellow.f35317b2.jpeg); }

.wrap-effect-2 .item:last-of-type {
  background-image: url(/static/media/yellow.f35317b2.jpeg); }

.wrap-effect-3 .item:hover, .wrap-effect-3 .item:active {
  -webkit-transform: translate3d(0, 0, 100px);
          transform: translate3d(0, 0, 100px);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); }

.wrap-effect-3 .item:first-of-type {
  background-image: url(/static/media/old.d3f402e6.jpeg); }

.wrap-effect-3 .item:nth-of-type(2) {
  background-image: url(/static/media/europe.6e273aba.jpeg); }

.wrap-effect-3 .item:nth-of-type(3) {
  background-image: url(/static/media/vote.7dcc11ac.jpeg); }

.wrap-effect-3 .item:nth-of-type(4) {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4742049/galshir-samurai.png"); }

.wrap-effect-3 .item:last-of-type {
  background-image: url(/static/media/euro.d3001032.jpeg); }

.wrap-effect-4 .item:hover, .wrap-effect-4 .item:active {
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  -webkit-filter: saturate(2) contrast(120%);
          filter: saturate(2) contrast(120%); }

.wrap-effect-4 .item:first-of-type {
  background-image: url(/static/media/five.b3545b87.jpeg); }

.wrap-effect-4 .item:nth-of-type(2) {
  background-image: url(/static/media/work.56ac9f4d.jpeg); }

.wrap-effect-4 .item:last-of-type {
  background-image: url(/static/media/ladefense.4665c8d7.jpeg); }

.inspire {
  border: 1px solid rgba(22, 76, 167, 0.6);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  margin: 10px;
  padding: 10px; }
  .inspire:after {
    background: #38ef7d;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .4;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
    -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10; }
  .inspire:hover:after, .inspire:active:after {
    left: 120%;
    -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1); }
  .inspire:hover .container-general .item-text, .inspire:active .container-general .item-text {
    display: block; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
            transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    -webkit-transform: rotate(10deg) translate3d(0, 0, 0);
            transform: rotate(10deg) translate3d(0, 0, 0); }
  50% {
    -webkit-transform: rotate(-10deg) translate3d(0, 0, 0);
            transform: rotate(-10deg) translate3d(0, 0, 0); }
  75% {
    -webkit-transform: rotate(5deg) translate3d(0, 0, 0);
            transform: rotate(5deg) translate3d(0, 0, 0); }
  100% {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
            transform: rotate(0deg) translate3d(0, 0, 0); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
            transform: rotate(0deg) translate3d(0, 0, 0); }
  25% {
    -webkit-transform: rotate(10deg) translate3d(0, 0, 0);
            transform: rotate(10deg) translate3d(0, 0, 0); }
  50% {
    -webkit-transform: rotate(-10deg) translate3d(0, 0, 0);
            transform: rotate(-10deg) translate3d(0, 0, 0); }
  75% {
    -webkit-transform: rotate(5deg) translate3d(0, 0, 0);
            transform: rotate(5deg) translate3d(0, 0, 0); }
  100% {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
            transform: rotate(0deg) translate3d(0, 0, 0); } }

.offline-content{
  height: 100vh;
}

.charte-main-container{
  width: 100%;
  text-align: center;
}



.charte-main-container .top-image.charte{
  background: url(/static/media/top-img.9bbbb4ae.jpg);
  padding-top: 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.charte-main-container .top-image img{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.charte-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
  font-weight: 200;

}

.charte-main-container .top-image-title h2 span{
  font-weight: normal;
}

.qcm-reminder{
  max-width: 30%;
  min-width: 300px;

  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}


.charte-content-header{
    width: 80%;
    margin: 0 auto;
    padding-top : 50px;
}

.charte-content{
  text-align: justify;
  width: 80%;
  margin: 0 auto;
}

.charte-content .image-sep{
  height: 250px;
  background-image: url(/static/media/sep-img.9412398c.jpeg);
  background-size: 100%;
  background-position: bottom;
  -webkit-transition:background-size .5s ease-in-out;
	transition:background-size .5s ease-in-out;
}

.charte-content .image-sep:hover {
	background-size: 120%;
}


@media (max-width:770px) {
    .charte-main-container .charte-content-description{
      display: inline-block;
    }

    .charte-main-container .charte-description h1{
      font-size: 1.5rem;
      margin-bottom: 50px;
    }
    .charte-main-container .charte-content-description .charte-content-chapitres{
      font-size: 1rem;
    }

    .charte-main-container .charte-content-description .red-line{
      border-top: 2px solid red;

    }
}

.preparer-main-container{
  width: 100%;
  text-align: center;
}

.preparer-main-container nav{
  width: 100%;
  position: fixed;
  z-index: 10;
}

.preparer-main-container .top-image{
  padding-top: 70px;
  background-image: url(/static/media/deroulement.18086968.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.preparer-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.preparer-main-container .top-image-title h2{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.preparer-main-container .top-image-title h2 span{
  color: #cf3030;
}


.preparer-main-container .questions-persos-content-header{
  padding: 40px;
  text-align: center;
}

.questions-perso-section{
  margin-bottom: 80px;
}

.wrap
{
  margin:50px auto 0 auto;
  width:100%;
  display:grid;
  grid-template-columns: repeat(3,1fr);
  align-items:space-around;
  max-width:1200px;
}

.tile
{
  width:380px;
  height:380px;
  margin:10px;
  background-color:#5caefa;
  display:inline-block;
  background-size:cover;
  position:relative;
  cursor:pointer;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44);
  overflow:hidden;
  color:white;
  font-family:'Roboto';

}
.tile img
{
  opacity: 0.75;
  height: 100%;
  position:absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index:0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.tile .text
{
/*   z-index:99; */
  text-align: left;
  position:absolute;
  padding:30px;
  height:calc(100% - 60px);
}
.tile h1
{
  color: white;
  font-weight:300;
  font-size: 2rem;
  margin:0;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
.tile h2
{
  font-weight:100;
  margin:20px 0 0 0;
  font-style:italic;
   -webkit-transform: translateX(200px);
           transform: translateX(200px);
}
.tile p
{
  font-weight:300;
  margin:20px 0 0 0;
  line-height: 25px;
/*   opacity:0; */
  -webkit-transform: translateX(-200px);
          transform: translateX(-200px);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.animate-text
{
  opacity:0;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.tile:hover
{
/*   background-color:#99aeff; */
  box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.64);
  -webkit-transform:scale(1.05);
          transform:scale(1.05);
}
.tile:hover img
{
  opacity: 0.2;
}

.tile:hover h1{
  display: none;
}

.tile:hover .animate-text
{
  -webkit-transform:translateX(0);
          transform:translateX(0);
  opacity:1;
}
.dots
{
  position:absolute;
  bottom:20px;
  right:30px;
  margin: 0 auto;
  width:30px;
  height:30px;
  color:currentColor;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;

}

.dots span
{
    width: 5px;
    height:5px;
    background-color: currentColor;
    border-radius: 50%;
    display:block;
  opacity:0;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.4s ease-out;
  transition: opacity 0.5s ease, -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.5s ease;
  transition: transform 0.4s ease-out, opacity 0.5s ease, -webkit-transform 0.4s ease-out;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);

}

.tile:hover span
{
  opacity:1;
  -webkit-transform:translateY(0px);
          transform:translateY(0px);
}

.dots span:nth-child(1)
{
   -webkit-transition-delay: 0.05s;
           transition-delay: 0.05s;
}
.dots span:nth-child(2)
{
   -webkit-transition-delay: 0.1s;
           transition-delay: 0.1s;
}
.dots span:nth-child(3)
{
   -webkit-transition-delay: 0.15s;
           transition-delay: 0.15s;
}


@media (max-width: 1200px) {
  .wrap {
    grid-template-columns: repeat(2,1fr);
  }
  .tile {
    margin: 10px auto 10px auto;
  }
}

@media (max-width: 830px) {
  .wrap {
    display: inline-block;
  }
  .tile {
    margin: 10px auto 20px auto;
    width: 90%;
  }

  .tile img{
    height: 600px;
    width: auto;
  }
}

.not-connected-quizz-content{
  height: 100vh;
  text-align: center;
  padding-top: 20px;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: white;
}

.landing-quizz-content{
  min-height: 100vh;
  text-align: center;
  padding-top: 20px;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: url(/static/media/study.5fc7d2f5.svg);
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}
.landing-quizz-content .quizz-presentation{
  width: 80%;
  margin: 0 auto;
  color: black;
}

.landing-quizz-content .quizz-presentation.text{
  color: black;
  padding: 20px;
  background: rgba(255, 255, 255, 0.85)
}
.landing-quizz-content .react-quiz-container{
  margin:0 auto;
  background: white;
  padding: 10px;
  color: black;
  border-radius: 10px;
}

.landing-quizz-content .react-quiz-container h3{
  color:#2193b0;
}

.landing-quizz-content  .nextQuestionBtn.btn{
  margin: 0 auto;
}

.landing-quizz-content .quizz-result{
  background: rgba(255, 255, 255, 0.35);
  padding: 50px;
  color: black;
}

@media (max-width:650px) {
  .landing-quizz-content .quizz-presentation{
    width: 100%;
  }

  .landing-quizz-content .quizz-presentation.title{
    padding: 20px;
  }

  .react-quiz-container{
    max-width: 90%;
  }

  .react-quiz-container .questionWrapper .btn {
    font-size: 12px;
  }

  .react-quiz-container .answerBtn {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .questionWrapperBody h3{
    font-size: 1.1rem;
  }

}

.constituer-main-container{
  min-height: 80vh;
  width: 100%;
  text-align: center;
}

.constituer-content p{
  text-align: justify;
}

.constituer-main-container .top-image{
  padding-top: 70px;
  background-position: top;
  background-image: url(/static/media/constituer.e3d6c681.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}

.constituer-main-container .top-image.procedure{
  background-image: url(/static/media/fleche.d3daa62c.jpg);
}

.constituer-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.constituer-main-container .top-image-title h1{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.constituer-main-container .top-image-title h1 span{
  color: #cf3030;
}


.constituer-main-container .constituer-content-header{
  width: 80%;
  margin : 0 auto;
  padding-top : 50px;
}
.constituer-main-container .constituer-content-header p{
  text-align: center;
}

.constituer-main-container .constituer-content-header p span{
  font-weight: 600;
}

.constituer-main-container .attention{
  margin : 20px;
  padding: 10px;
  border: solid rgba(246, 57, 46, 0.84) 1px;
  background: rgba(185, 67, 53, 0.1);
  border-radius: 10px;
}

.constituer-main-container .constituer-top-image img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.constituer-main-container a{
  color:  rgb(0, 130, 179);
  text-decoration: none;
}

.constituer-main-container a:hover{
  text-decoration: none;
}

.constituer-main-container ul{
  text-align: center;
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;

}

.constituer-main-container li,.constituer-main-container .list-spaced{
  background: #7ed0e9;
  padding: 10px;
  margin: 0 0 10px 0;
  -webkit-transition: background 0.5s ease-out;
  transition: background 0.5s ease-out;
}

.constituer-main-container ul .list-spaced{
  margin: 30px 0 20px 0;
  width: 110%;
}

.constituer-main-container .list-secondary{
  background: white;
  border: solid 1px #7ed0e9;
  color : black;
  width: 80%;
  margin : 0 auto;
  margin-bottom : 20px;
}

.constituer-main-container li:hover{
  color: white;
  background: rgb(30, 140, 164);
}

.constituer-main-container .list-secondary:hover{
  background: white;
  color : #7ed0e9;
}

/*** PROCEDURE **/
.constituer-section.procedure.section-1-container{
  text-align: center;
}

.constituer-section.procedure.section-1-container .form-selection{
    width: 70%;
    margin: 0 auto;
}

.constituer-section.procedure.section-1-container .form-selection ul{
  text-align: left;
}

.constituer-section.procedure.section-1-container input[type="radio"]{
  margin: 0 10px 0 10px;
}

.constituer-main-container .procedure-form-result{
  background: #7ed0e9;
  padding: 10px;
  margin: 10px 0 10px 0;
}

.constituer-main-container .procedure-form-result p{
  text-align: center;
  font-size: 1.2rem;
}
.constituer-main-container .procedure-form-result p span{
  text-align: center;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
}


.constituer-section {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.constituer-section .option_select{
  margin-top:10px;
  margin-bottom: 10px;
}

.constituer-section .deposer-result .deposer-result-content{
  border: solid 1px rgb(83, 202, 252);
  background: rgba(100, 170, 180, 0.2);
  margin-top: 50px;
  margin-bottom : 100px;
  padding: 10px;
}

.constituer-section .deposer-result .deposer-result-content .links{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-top : 20px;
}
.constituer-section .deposer-result .deposer-result-content .links p {
  text-align: center;
}

.constituer-main-container button{
  margin: 50px;
}

.constituer-content-header.instruction{
  width: 80%;
  padding: 20px;
  margin: 0 auto;
}

/** DECISION **/
.constituer-content-header.decision{
  width: 60%;
  margin-bottom : 100px;
}
.constituer-section.decision.section-1.left{
  text-align: left;
}

.constituer-section.decision.section-1-container h3{
  text-align: center;
  margin-top: 50px;
}
.constituer-section.decision.section-1-container button{
  width: 100%;
  margin: 0 auto;
}

@media (max-width:750px) {
  .constituer-main-container .constituer-content-header{
    width: 90%;
    margin: 0 auto;
    padding: 0;
    padding-top : 30px;
  }

  .constituer-main-container ul{
    padding: 0;
  }

  .constituer-section.procedure.section-1-container .form-selection{
    width: 80%;
  }

  .constituer-section {
    width: 95%;
    margin: 0 auto;
  }

  .constituer-section .deposer-result .deposer-result-content .links{
    display: inline-block;
  }


}

.checklist-conditions {
  padding: 5px;
  border: solid 1px #007bff;
  border-radius: 5px; }

.checklist-conditions .subtitle {
  padding: 20px; }

.checklist-conditions .content-list li {
  border: solid 1px #007bff;
  background: rgba(0, 123, 255, 0.54); }

.checklist-conditions .content-list .cond-list {
  padding-left: 20px;
  padding-right: 10px;
  display: grid;
  grid-template-columns: 1fr 20fr;
  text-align: center;
  -webkit-transition: background 0.5s ease-in;
  transition: background 0.5s ease-in;
  grid-column-gap: 5px; }

.checklist-conditions .content-list .cond-list input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.checklist-conditions .content-list .cond-list .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee; }

@media (max-width: 750px) {
  .conditions-result .content-list .cond-list {
    padding-left: 0px; } }

.page {
  /*background: url("./img/bg-2.jpg");*/
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#6dd5ed), to(#008cde));
  background: -webkit-linear-gradient(left, #6dd5ed, #008cde);
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  text-align: center;
  padding-bottom: 150px;
}

.page .title {
  background: rgb(255, 255, 255, 0.7);
  color : #3d92ca;
  padding: 20px;
  font-size: 1.4rem;
}

.page .img-sec {
  min-width: 150px;
  max-width: 20%;
  margin-top:5%;
}

.page .form-title{
  background: rgb(255, 255, 255, 0.5);
  margin-top : 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: 300;
}

.page .form-title span{
  color: rgb(235, 72, 72);
  font-weight: 400;
}

.hp-form {
  max-width: 560px;
  min-height: 452px;
  margin: 0 auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
  border: 1px solid white;
  color: black;
  text-align: left;
  box-shadow: 0 10px 10px 0 rgba(#000000, 0.4);
}

.hp-form p {
  font-weight: 200;
  color: black;
}

.hp-form #price{
  color: #b4b4b4;
}

.hp-form .packages .form-check-label{
  display: grid;
  grid-template-columns: 3fr 1fr;
}


.hp-form .content-center{
  text-align: center;
}


.hp-form .content-center img{
  max-width: 30%;
}

.hp-form .divOff {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 1;
}

.hp-form .divOff.hidden {
  opacity: 0;
  pointer-events:none;
}

.hp-form p.form-subtitle {
  text-align: center;
}

.hp-form .form-group .field-error.form-control {
  border: 1px rgb(198, 63, 63) solid;
}

.hp-form .form-information {
  font-style: italic;
  font-size: 0.6rem;
  font-weight: lighter;
  margin-top: -10px;
}

.hp-form .btn {
  width: 100%;
  margin: 0 auto;
}

.hp-form .btn.next .send {
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: white;
  border: 1px solid green;
  border-radius: 20px;
  color: green;
}

.hp-form .btn.back .send {
  width: 50%;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0;
  background-color: white;
  border: 1px solid gray;
  border-radius: 20px;
  color: gray;
}

.hp-form .btn.next .send:hover {
  background-color: green;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  -webkit-transition: 1s;
}

.hp-form .btn.back .send:hover {
  background-color: gray;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  -webkit-transition: 1s;
}

.bill-item {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.hp-form .cgv-text.field-error,.cgv-text.field-error p, .cgv-text.field-error a{
  color: rgb(198, 63, 63);
}

.hp-form .cgv-text label{
  padding: 0.4rem;
}

.hp-form .option .photo {
  padding: 20px;
  width: 100%;
  text-align: center;
  float: left;
  height: 240px;
}

.hp-form .option .photo img {
  max-height: 100%;
  width: 100%;
  background: white;
}

.hp-form .option .description #price{
         color: #515151;
         font-weight: 300;
         padding-top: 15px;
         margin: 0;
         font-size: 30px;
         font-weight: 300;
}

.hp-form .option .description h3 {
        color: #515151;
        margin: 0;
        text-transform: uppercase;
        font-weight: 500;
       }

.hp-form .option .description  h4 {
         margin: 0;
         color: #727272;
         text-transform: uppercase;
         font-weight: 500;
         font-size: 12px
       }

.hp-form .option .description  p,.hp-form .option .option-choice a {
  text-align: justify;
  font-size: 15px;
  line-height: 20px;
  color: #727272;
  text-decoration: none;
  margin: 0;
}

.hp-form .form-text span{
  color: #a60000;
}

.hp-form .option .option-choice {
  text-align: right;
}

.hp-form .option .btn.next .send {
     outline: 0;
     border: 0;
     background: none;
     border: 1px solid #d9d9d9;
     padding: 10px 20px;
     margin-bottom: 30px;
     color: #515151;
     text-transform: uppercase;
     width: 80%;
     font-family: inherit;
     margin-right: 5px;
     -webkit-transition: all 0.3s ease;
     transition: all 0.3s ease;
     font-weight: 500;
 }

.hp-form .option .btn.next .send:hover {
  background: darken(white, 2%);
  border: 1px solid #aedaa6;
  color: #aedaa6;
   cursor: pointer;
}

.hp-form .option .btn.next .send i{
  padding-left: 10px;
  padding-right: 10px;
}

.hp-form .visible-form{
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 600px;
          perspective: 600px;
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: rotateX(0deg);
  transition: .5s;
}

.hp-form .visible-form.hidden{
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);
}


@media (max-width:500px){
  .hp-form {
    border-radius: 0px;
  }
}

.navbar .navbar-brand img{
  width: 10%;
  min-width: 50px;
  padding: 10px;
}

.navbar .navbar-brand{
  max-width: 30%;
  font-size: 0.9rem;
}


.navbar .navbar-brand span{
  color : #008cde;
}

.nav-bar{
  font-family: Helvetica, sans-serif;
  width: 100%;
  background-color: #F6F5F6;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 1px 2px 1px rgb(0,0,0,0.2);
  font-size: 0.6rem;

}
.navbar .nav-item a{
  font-size: 0.8rem;
}

.navbar .nav-item{
  margin-left: 10px;
}

.navbar .nav-item span{
  font-weight: bold;
}
.navbar .nav-item span i{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar .mon-compte-header{
  border: solid 0.1rem #008cde;
}

.navbar .mon-compte-header.dropdown-toggle.nav-link{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #008cde;
}

.navbar .mon-compte-header.dropdown-toggle.nav-link:hover{
  color: #008cde;
}

.nav-bar .experts-link{
  background-color: #4284fb;
  text-align: right;
  padding-right: 10px;
  padding-top : 0.1em;
  padding-bottom: 0.1em;
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.nav-bar .experts-link a {
  color: white;
  padding-left: 1rem;
}

footer {
  width: 100%;
  padding: 25px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222222;
}

footer p {
  font-size: 0.6rem;
  margin: 0;
  font-weight: bold;
}

footer i{
  padding: 10px;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

footer a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

footer a:hover, footer a:focus, footer a:active, footer a.active {
  text-decoration: none;
}

footer .footer-container{
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding:0;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 20px;
}

footer .footer-container div:last-child{
  text-align: center;
  padding-right: 10px;
}


@media (max-width:500px){
  footer .footer-container{
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    text-align: center;

  }

  footer .footer-container div:last-child{
    text-align: center;
  }
}

