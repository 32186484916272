@import url('https://fonts.googleapis.com/css?family=Arvo');

.personaliteCards{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: #f5f4f4;
    font-size: 13px;
    font-family: 'Arvo', monospace;

    @supports(display: grid) {
      display: block;
    }


  .message {
    border: 1px solid #d2d0d0;
    padding: 2em;
    font-size: 1.7vw;
    box-shadow: -2px 2px 10px 0px rgba(#444, 0.4);

    @supports(display: grid) {
      display: none;
    }
  }

  .section {
    display: none;
    padding: 2rem;

    @media screen and (min-width: 768px) {
      padding: 4rem;
    }

    @supports(display: grid) {
      display: block;
    }
  }

  h1 {
    font-size: 2rem;
    margin: 0 0 1.5em;
  }

  .grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 150px;
    grid-auto-flow: row dense;
  }

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #0c9a9a;
    color: #fff;
    grid-column-start: auto;
    grid-row-start: auto;
    color: #fff;
    background-size: cover;
    background-position: center;
    box-shadow: -2px 2px 10px 0px rgba(#444, 0.4);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    counter-increment: item-counter;
    width: 100%;


    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;
    }



    &--medium {
      grid-row-end: span 2;
    }

    &--large {
      grid-row-end: span 3;
    }

    &--full {
      grid-column-end: auto;

      @media screen and (min-width: 768px) {
        grid-column: 1/-1;
        grid-row-end: span 2;
      }
    }

    &__details {
      position: relative;
      z-index: 1;
      padding: 15px;
      color: #444;
      background: #fff;
      letter-spacing: 1px;
      color: #828282;
      transition : opacity 0s ease-in-out;

      &:before {
        font-weight: bold;
        font-size: 1.1rem;
        padding-right: 0.5em;
        color: #444;
      }
    }

    &:hover {
      transform: scale(1.05);

      &:after {
        opacity: 0;
      }

      .description{
        display: block;
        opacity: 1;
      }

      .item__details{
        opacity: 0;
      }
    }

  }

  .description{
    display: flex;
    display: none;
    position: relative;
    z-index: 1;
    padding: 0;
    height: 50%;
    color: #444;
    background: rgba(#ffffff, 0.8);
    padding: 20px;
    letter-spacing: 1px;
    color: #828282;
    opacity: 0;

    transition : opacity 3s ease;

    &:before {
      font-weight: bold;
      font-size: 1.1rem;
      padding-right: 0.5em;
      color: #444;
    }
  }

}


@media (max-width:500px){
  .personaliteCards{
    .section{
      padding: 10px;
    }
    .grid {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      grid-auto-rows: 150px;
      grid-auto-flow: row dense;
    }

    .item {
      font-size: 0.6rem;
    }
  }

}
