.deroulement-main-container{
  width: 100%;
  text-align: center;
}

.deroulement-content p{
  text-align: justify;
}

.deroulement-main-container nav{
  width: 100%;
  position: fixed;
}

.deroulement-main-container .top-image{
  padding-top: 70px;
  /*background-image: url('../img/deroulement.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  min-height: 200px;
}

.deroulement-main-container .top-image-title{
  height: 100%;
  vertical-align: middle;
}

.deroulement-main-container .top-image-title h1{
  background: rgba(255, 255, 255, 0.7);
  line-height: 4rem;
  vertical-align: middle;
  color:  #3d92ca;
}

.deroulement-main-container .top-image-title h1 span{
  color: #cf3030;
}


.deroulement-main-container .deroulement-content-header{
  width: 80%;
  margin : 0 auto;
  padding: 40px;
}
.deroulement-main-container .deroulement-content-header p{
  text-align: center;
}

.deroulement-main-container .deroulement-content-description{
  padding: 50px;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-column-gap: 10px;
}

.deroulement-main-container .deroulement-top-image img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.deroulement-section{
  margin-top: 20px;
}

.deroulement-section h2{
  margin-bottom: 40px;
}

.deroulement-section h3{
  color:  #3d92ca;
}

.deroulement-section.section-1-container{
  width: 90%;
  min-width: 800px;
  margin: 0 auto;
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
  text-align: left;
  background-image: url("../img/meeting-1.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin-bottom: 40px;
}

.deroulement-section.section-1-right{
  padding: 50px;
  background: rgba(255, 255, 255, 0.85);
}

.deroulement-section.section-1-bottom-left{
  padding: 50px;
  background: rgba(255, 255, 255, 0.85);
}

.deroulement-section.section-end{
  margin: 50px;
}


@media (max-width:900px) {
  .deroulement-section.section-1-container{
    display: inline-block;
    min-width: inherit;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .deroulement-main-container .deroulement-content-header{
    width: 100%;
  }
}
