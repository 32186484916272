html,body {
  height: 100%;
  margin:0;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-weight: lighter !important;
}

@media (max-width:500px) {
  h2{
    font-size: 1rem;
  }
}
