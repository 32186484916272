.my-account-main-container{
  width: 100%;
  min-height: 80vh;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #008cde);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #008cde); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.my-account-content{
  margin: 0 auto;
  padding : 50px;
  color: white;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
}

.my-account-content .my-infos{
  text-align: center;
  width: 80%;
  max-width: 850px;
  margin: 0 auto;
  margin-top : 50px;
  padding: 20px;
  padding-top: 50px;
  border-radius: 30px;
  background-color: white;
  color: black;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.51);
}

.my-account-content .my-infos label{
  font-weight: bold;
}

.my-account-content .my-infos .btn{
  width: 250px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 40px;
}


.my-account-content .my-infos .multiple-buttons{
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.my-account-content .register-link{
  text-decoration: none;
  color: white;
}
.lg-form .form-group .field-error.form-control {
  border: 1px red solid;
}

.my-infos-content{
  display: none;
}

.my-infos-content.active{
  display: block;
}

.my-account-content .scores-history .history-chart{

}

.my-account-content .scores-history .values{
  margin-top : 40px;
}

.my-account-content .nav-item:hover{
  cursor: pointer;
}

.my-account-content .active.nav-link:hover{
  color: black;
}



.services-souscrits .table-wrapper{
    margin: 10px 70px 70px;
    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.services-souscrits .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.services-souscrits .fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
}

.services-souscrits .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.services-souscrits .fl-table thead th {
    color: #ffffff;
    background: #4fabc3;
}


.services-souscrits .fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}

.services-souscrits .fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
    .services-souscrits .fl-table {
        display: block;
        width: 100%;
    }
    .services-souscrits .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .services-souscrits .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .services-souscrits .fl-table thead th:last-child{
        border-bottom: none;
    }
    .services-souscrits .fl-table thead {
        float: left;
    }
    .services-souscrits .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .services-souscrits .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .services-souscrits .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .services-souscrits .fl-table tbody tr {
        display: table-cell;
    }
    .services-souscrits .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .services-souscrits .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .services-souscrits .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }
    .services-souscrits .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }
    .services-souscrits .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

@media (max-width:800px) {
  .my-account-content{
    padding-left: 0px;
    padding-right: 0px;
  }
  .my-account-content .my-infos .multiple-buttons{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .my-account-content .my-infos{
    width: 100%;
    border-radius: 0px;
  }


}
