.navbar .navbar-brand img{
  width: 10%;
  min-width: 50px;
  padding: 10px;
}

.navbar .navbar-brand{
  max-width: 30%;
  font-size: 0.9rem;
}


.navbar .navbar-brand span{
  color : #008cde;
}

.nav-bar{
  font-family: Helvetica, sans-serif;
  width: 100%;
  background-color: #F6F5F6;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 1px 2px 1px rgb(0,0,0,0.2);
  font-size: 0.6rem;

}
.navbar .nav-item a{
  font-size: 0.8rem;
}

.navbar .nav-item{
  margin-left: 10px;
}

.navbar .nav-item span{
  font-weight: bold;
}
.navbar .nav-item span i{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar .mon-compte-header{
  border: solid 0.1rem #008cde;
}

.navbar .mon-compte-header.dropdown-toggle.nav-link{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #008cde;
}

.navbar .mon-compte-header.dropdown-toggle.nav-link:hover{
  color: #008cde;
}

.nav-bar .experts-link{
  background-color: #4284fb;
  text-align: right;
  padding-right: 10px;
  padding-top : 0.1em;
  padding-bottom: 0.1em;
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.nav-bar .experts-link a {
  color: white;
  padding-left: 1rem;
}
